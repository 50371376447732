import LoadingScreen from 'src/components/Screen/LoadingScreen';
import React from 'react';
import { getQueryString } from 'src/utils/useFunctions';
import { getUserHomepage } from 'src/utils/useUser';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';

const Homepage: React.FunctionComponent = () => {
  
  const dispatch = useAppDispatch();
  const location = useLocation();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const loadingData = useAppSelector((state: any) => state.loading);
  const userData = useAppSelector((state: any) => state.user);

  const queryString = getQueryString(location);

  return (
    <>
      <LoadingScreen/>
      {
        userData.userStatus === "loggedIn" && loadingData.isLanguageLoaded && loadingData.isUserLoaded && loadingData.isUserDataLoaded ? (
          <Navigate to={`${getUserHomepage(dispatch, dataData, layoutData, userData)}${queryString}`} state={{ from: location }}/>
        ) : (
          <Navigate to={`/auth/login${queryString}`} state={{ from: location }}/>
        )
      }
    </>
  );
}

export default Homepage;