import * as AuthService from '../../../services/auth.service';
import React, { useCallback } from 'react';
import { getAppData } from 'src/utils/useApp';
import { getQueryString } from 'src/utils/useFunctions';
import { setDeinitializeUserController } from 'src/store/actions/controllers.actions';
import { setUserStatus } from '../../../store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate } from 'react-router';

const Logout: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userData = useAppSelector((state: any) => state.user);

  const queryString = getQueryString(location);

  const handleLogout = useCallback(() => {
    const savedData = getAppData();
    if(userData.userStatus === "loggedIn") {
      const savedUsers = savedData.users ? savedData.users : [];
      dispatch(setDeinitializeUserController(true));
      navigate(savedUsers.length > 1 ? `/auth/switch${queryString}` : `/auth/login${queryString}`);
    } else {
      const savedUsers = savedData.users ? savedData.users : [];
      dispatch(setDeinitializeUserController(true));
      navigate(savedUsers.length > 1 ? `/auth/switch${queryString}` : `/auth/login${queryString}`);
    }
  }, [dispatch, navigate, userData.userStatus, queryString]);

  useEffect(() => {
    dispatch(setUserStatus("loggingOff"));
    AuthService && AuthService.logout().then(() => {
      handleLogout();
    }).catch(() => {
      handleLogout();
    });
  }, [handleLogout, dispatch], []);
  
  return null;
}

export default Logout;