import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import EndOfScroll from 'src/components/Layouts/EndOfScroll';
import IconButton from 'src/components/Buttons/IconButton';
import NormalButton from 'src/components/Buttons/NormalButton';
import NotFound from 'src/components/Layouts/NotFound';
import React, { useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import ThreadItem from '../ThreadItem';
import useBreakpoint from 'src/utils/useBreakpoint';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getQueryString } from 'src/utils/useFunctions';
import { setCommunicationThreadID, setCommunicationViewMode } from 'src/store/actions/communication.actions';
import { setWarningModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props {
  isLoading?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  threadsList: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 20px)',
    maxWidth: 'calc(100% - 20px)',
    gap: '8px',
    maxHeight: 'calc(100% - 12px)',
    overflow: (props: Props) => {
      if(props.isLoading) return 'hidden';
      else return 'auto';
    },
    padding: '0px 10px',
    flex: '1 1 100%',
    marginTop: '12px',
    position: 'relative',
    '& > button': {
      margin: '16px auto 0',
      width: 'fit-content',
      '& svg': {
        width: '24px',
        height: '24px',
      },
    },
  },
  loading: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    height: '100%',
  },
  loadingSmall: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'sticky',
    top: '0',
    zIndex: '2',
    '& > div': {
      backgroundColor: theme.colors.white,
      padding: '12px 12px',
      borderRadius: '6px',
      transform: 'scale(0.5)',
    },
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  endOfScroll: {
    '& > span': {
      fontSize: '14px',
      backgroundColor: theme.colors.grey[200],
    },
  },
  threadItem: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% - 16px)',
    maxWidth: 'calc(100% - 16px)',
    alignItems: 'center',
    padding: '6px 8px',
    transition: 'all 0.25s',
    borderRadius: '12px',
    gap: '10px',
    cursor: 'default',
    backgroundColor: theme.colors.grey[150],
    position: 'relative',
    '&:hover': {
      backgroundColor: theme.colors.grey[150],
      '& > button': {
        opacity: '1',
      },
    },
    '& > button': {
      width: '28px',
      height: '28px',
      opacity: '0',
      transition: 'opacity 0.25s',
      position: 'absolute',
      top: '50%',
      right: '8px',
      transform: 'translateY(-50%)',
      '& > svg': {
        width: '100%',
        height: '100%',
      },
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 calc(100% - 30px)',
    gap: '10px',
    maxWidth: 'calc(100% - 30px)',
    position: 'relative',
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 20px',
    '& > svg': {
      width: '18px',
      height: '18px',
    },
  },
  avatar: {
    position: 'relative',
    width: '46px',
    height: '46px',
    borderRadius: '12px',
    '& > div': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[325],
      borderRadius: 'inherit',
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
      overflow: 'hidden',
    },
  },
  detail: {
    maxWidth: 'calc(100% - 58px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '4px',
    '& > span': {
      fontSize: '14px',
      fontWeight: '500',
      display: 'block',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& > div': {
      display: 'flex',
      gap: '8px',
      '& > p': {
        fontSize: '12px',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontFamily: `'Poppins', 'Noto Color Emoji', sans-serif`,
        fontWeight: '400',
      },
      '& > span': {
        fontSize: '12px',
        flex: '0 0 auto',
        '&::before': {
          content: `'•'`,
          paddingRight: '8px',
        },
      },
    },
  },
}));

type ThreadsListType = {
  isLoading: boolean;
  isLoadingMessages: any;
  isEndOfScroll: any;
  currentThreadID: any;
  page: any;
  order: any;
  loadThreads: any;
};

const ThreadsList: React.FunctionComponent<ThreadsListType> = ({ isLoading, isLoadingMessages, isEndOfScroll, currentThreadID, page, order, loadThreads }) => {

  const classes = useStyles({
    isLoading: isLoading,
  });
  
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tab: viewMode }: any = useParams();
  const communicationData = useAppSelector((state: any) => state.communication);
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);
  const threadsList = communicationData.list;

  const queryString = getQueryString(location);
  const breakpoint: any = useBreakpoint();
  const cantCreateNewThread = userData.userObject.schoolSetting.every((schoolSetting: any) => schoolSetting.modules && schoolSetting.modules.communication && schoolSetting.modules.communication.banned === true);

  const layouts: any = {
    xxxxl: "desktop",
    xxxl: "desktop",
    xxl: "desktop",
    xl: "desktop",
    lg: "desktop",
    bg: "desktop",
    md: "mobile",
    co: "mobile",
    sm: "mobile",
    xs: "mobile",
  };

  const customViewMode = communicationData.viewMode;
  const layout = customViewMode ? "mobile" : layouts[breakpoint];

  const handleNavigate = (loc: any) => {
    if(customViewMode) {
      dispatch(setCommunicationThreadID(null));
      dispatch(setCommunicationViewMode(loc));
    } else {
      navigate(`/communication/${loc}${queryString}`);
    }
  };

  const getUserData = useCallback((userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  }, [dataData.users]);

  const getThreadsList = () => {
    const threads = threadsList.map((x: any) => { return {...x}});
    threads.sort((a: any, b: any) => {
      const dateA: any = new Date(a.lastMessage.createdDate);
      const dateB: any = new Date(b.lastMessage.createdDate);
      return order === "desc" ? dateB - dateA : dateA - dateB;
    });
    return threads;
  };

  const handleBackToThreads = () => {
    handleNavigate("threads");
  };

  const handleNewMessage = () => {
    if(cantCreateNewThread) {
      const settings = {
        isOpen: true,
        title: t('function_unavailable'),
        content: t('function_unavailable_communication_new'),
      };
      dispatch(setWarningModal(settings));
      return;
    }
    handleNavigate("new");
  };

  const newUserData = getUserData(-1);

  const threadsListRef: any = useRef(null);

  const pageRef = useRef(page);
  const orderRef = useRef(order);

  useEffect(() => {
    const threadsListRefCurrent = threadsListRef.current;
    if(threadsListRefCurrent) {
      const handleScroll = () => {
        const scrollTop = threadsListRefCurrent.scrollTop;
        const scrollHeight = threadsListRefCurrent.scrollHeight;
        const offsetHeight = threadsListRefCurrent.offsetHeight;
        if(scrollTop === (scrollHeight - offsetHeight)) {
          const newPage = pageRef.current + 1;
          pageRef.current = newPage;
          loadThreads(newPage, orderRef.current);
        }
      };
      threadsListRefCurrent.addEventListener('scroll', handleScroll);
      return () => {
        if(threadsListRefCurrent) {
          threadsListRefCurrent.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [loadThreads], []);
  
  return (
    <div className={classes.threadsList} ref={threadsListRef}>
      {
        (isLoading && getThreadsList().length === 0) ? (
          <div className={classes.loading}>
            <div className={classes.spinner}>
              <CircularProgress/>
            </div>
          </div>
        ) : (
          <>
            {
              isLoading ? (
                <div className={classes.loadingSmall}>
                  <div className={classes.spinner}>
                    <CircularProgress/>
                  </div>
                </div>
              ) : null
            }
            {
              viewMode === "new" ? (
                <div className={classes.threadItem}>
                  <div className={classes.info}>
                      <AuthenticatedImage className={classes.avatar} thumbLink={newUserData.photo.thumbLink}/>
                    <div className={classes.detail}>
                      <span>{t('communication_new_thread')}</span>
                    </div>
                  </div>
                  <IconButton onClick={handleBackToThreads} tooltip={t('cancel')} tooltipPosition='bottom' tooltipMaxWidth={400}>
                    <SVG src="close"/>
                  </IconButton>
                </div>
              ) : null
            }
            {
              getThreadsList().length === 0 ? (
                <>
                  <NotFound text={t('communication_no_threads')}/>
                  {
                    layout === "mobile" ? (
                      <NormalButton startIcon={<SVG src="pencil-new"/>} onClick={handleNewMessage}>{t('communication_button')}</NormalButton>
                    ) : null
                  }
                </>
              ) : (
                <>
                  {
                    getThreadsList().filter((item: any) => item.threadID).map((item: any, key: any) => (
                      <ThreadItem isLoadingMessages={isLoadingMessages} currentThreadID={currentThreadID} threadID={item.threadID} key={`k_${key}`}/>
                    ))
                  }
                </>
              )
            }
            {
              (isEndOfScroll && page !== 1) ? (
                <EndOfScroll className={classes.endOfScroll} text={t('no_more_threads')}/>
              ) : null
            }
          </>
        )
      }
    </div>
  );
};

export default ThreadsList;