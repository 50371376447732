import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import DropdownWithMenu from 'src/components/Buttons/DropdownWithMenu';
import moment from 'src/utils/moment';
import React, { useCallback } from 'react';
import RelativeDate from 'src/components/Layouts/RelativeDate';
import SVG from 'src/components/Images/SvgRenderer';
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from 'src/utils/useContextMenu';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { deleteThreadData, setCommunicationThreadID, setCommunicationViewMode, updateThreadData } from 'src/store/actions/communication.actions';
import { getQueryString } from 'src/utils/useFunctions';
import { getUserSetting } from 'src/utils/useUser';
import { isMobile } from 'react-device-detect';
import { mergeProps, useLongPress } from 'react-aria';
import { setConfirmModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useLocation, useNavigate } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  currentThreadID: any;
  isThreadActive: any;
  isLoadingMessages: any;
  isUnread: any;
  isMobile: any;
};

const useStyles = createUseStyles((theme: any) => ({
  threadItemWrapper: {
    display: 'flex',
    width: '100%',
  },
  threadItem: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% - 16px)',
    maxWidth: 'calc(100% - 16px)',
    alignItems: 'center',
    padding: '6px 8px',
    transition: 'all 0.25s',
    borderRadius: '12px',
    gap: '10px',
    cursor: (props: Props) => {
      if(props.isLoadingMessages) return 'default';
      else return 'pointer';
    },
    backgroundColor: (props: Props) => {
      if(props.currentThreadID) return theme.colors.grey[150];
      else return theme.colors.white;
    },
    '&:hover': {
      backgroundColor: (props: Props) => {
        if(props.isLoadingMessages) return theme.colors.grey[150];
        else return theme.colors.grey[200];
      },
      '& > div:first-of-type': {
        '& > div': {
          '&:last-of-type': {
            opacity: (props: Props) => {
              if(!props.isMobile) return '1';
              else return '0';
            },
          },
        },
      },
    },
    '& > div:first-of-type': {
      '& > div': {
        '&:last-of-type': {
          opacity: (props: Props) => {
            if(props.isThreadActive && !props.isMobile) return '1';
            else return '0';
          },
          transition: 'opacity 0.25s',
          position: 'absolute',
          top: '50%',
          right: '4px',
          transform: 'translateY(-50%)',
        },
      },
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 calc(100% - 30px)',
    gap: '10px',
    maxWidth: 'calc(100% - 30px)',
    position: 'relative',
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 20px',
    '& > svg': {
      width: '18px',
      height: '18px',
    },
    '& > span': {
      '& > svg': {
        width: '14px',
        height: '14px',
        color: theme.colors.primaryBlue[600],
      },
    },
  },
  viewedAvatar: {
    width: '18px',
    height: '18px',
    position: 'relative',
    '& > div': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[325],
      borderRadius: '6px',
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
    }
  },
  avatars: {
    display: 'flex',
    alignItems: 'center',
    width: '48px',
    height: '48px',
  },
  singleGroup: {
    display: 'flex',
    alignItems: 'center',
    width: '48px',
    height: '48px',
  },
  smallGroup: {
    position: 'relative',
    display: 'flex',
    width: '48px',
    borderRadius: '0%',
    '& > div': {
      position: 'absolute',
      width: '32px',
      height: '32px',
      borderRadius: '8px',
      '&:first-of-type': {
        top: 'calc(100% - 24px)',
        left: '0px',
      },
      '&:last-of-type': {
        bottom: 'calc(100% - 24px)',
        right: '0px',
      },
    },
  },
  mediumGroup: {
    position: 'relative',
    display: 'flex',
    width: '48px',
    borderRadius: '0%',
    '& > div': {
      position: 'absolute',
      width: '26px',
      height: '26px',
      borderRadius: '6px',
      '&:nth-child(1)': {
        top: 'calc(100% - 26px)',
        left: '11px',
      },
      '&:nth-child(2)': {
        bottom: 'calc(100% - 20px)',
        left: '0px',
      },
      '&:nth-child(3)': {
        bottom: 'calc(100% - 20px)',
        right: '0px',
      },
    },
  },
  largeGroup: {
    position: 'relative',
    display: 'flex',
    width: '48px',
    borderRadius: '0%',
    '& > div': {
      position: 'absolute',
      width: '24px',
      height: '24px',
      borderRadius: '6px',
      '&:nth-child(1)': {
        bottom: 'calc(100% - 24px)',
        left: '0px',
      },
      '&:nth-child(2)': {
        bottom: 'calc(100% - 24px)',
        right: '0px',
      },
      '&:nth-child(3)': {
        top: 'calc(100% - 24px)',
        left: '0px',
      },
      '&:nth-child(4)': {
        top: 'calc(100% - 24px)',
        right: '0px',
      },
    },
  },
  bigGroup: {
    position: 'relative',
    display: 'flex',
    width: '48px',
    borderRadius: '0%',
    '& > div': {
      position: 'absolute',
      width: '24px',
      height: '24px',
      borderRadius: '6px',
      '&:nth-child(1)': {
        bottom: 'calc(100% - 24px)',
        left: '0px',
      },
      '&:nth-child(2)': {
        bottom: 'calc(100% - 24px)',
        right: '0px',
      },
      '&:nth-child(3)': {
        top: 'calc(100% - 24px)',
        left: '0px',
      },
    },
    '& > span': {
      position: 'absolute',
      top: 'calc(100% - 23.5px)',
      right: '0.5px',
      width: '23px',
      height: '23px',
      backgroundColor: theme.colors.grey[325],
      color: theme.colors.black,
      borderRadius: '6px',
      fontSize: '70%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  avatar: {
    position: 'relative',
    width: '46px',
    height: '46px',
    borderRadius: '12px',
    '& > div': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[325],
      borderRadius: 'inherit',
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
      overflow: 'hidden',
    },
  },
  detail: {
    maxWidth: 'calc(100% - 58px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    '& > span': {
      fontSize: '14px',
      fontWeight: '500',
      display: 'block',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& > div': {
      display: 'flex',
      gap: '8px',
      '& > p': {
        fontSize: '12px',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontFamily: `'Poppins', 'Noto Color Emoji', sans-serif`,
        fontWeight: (props: Props) => {
          if(props.isUnread) return '700';
          else return '400';
        },
        '& > span': {
          color: theme.colors.primaryBlue[500],
          fontWeight: '600',
        },
      },
      '& > span': {
        fontSize: '12px',
        flex: '0 0 auto',
        '&::before': {
          content: `'•'`,
          paddingRight: '8px',
        },
      },
    },
  },
  dropdownMenu: {
    position: 'relative',
  },
  dropdownMenuPopover: {
    borderRadius: '14px',
  },
  dropdownMenuButton: {
    backgroundColor: theme.colors.grey[100],
  },
  moreButton: {
    backgroundColor: theme.colors.grey[200],
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: theme.colors.grey[300],
    },
    '&:disabled': {
      color: theme.colors.grey[560],
      backgroundColor: theme.colors.grey[75],
    },
    '& > svg': {
      width: '14px',
      height: '14px',
    },
  },
}));

type ThreadItemType = {
  currentThreadID: any;
  threadID: any;
  isLoadingMessages: any;
};

const ThreadItem: React.FunctionComponent<ThreadItemType> = ({ currentThreadID, threadID, isLoadingMessages }) => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const communicationData = useAppSelector((state: any) => state.communication);
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);
  const communicationService = useAppSelector((state: any) => state.services).communicationService;

  const [state, setState] = useStates({
    isThreadActive: false,
    currentTarget: null,
  });

  const getUserData = useCallback((userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  }, [dataData.users]);

  const getThreadData = useCallback((threadID: any) => {
    return communicationData.list.filter((item: any) => item.threadID === threadID).length === 0 ? null : communicationData.list.find((item: any) => item.threadID === threadID);
  }, [communicationData.list]);

  const threadData = getThreadData(threadID);
  const isThreadReady = threadData && threadData.length !== 0;
  const users = isThreadReady ? threadData.users.filter((item: any) => item.userID !== userData.userObject.userID && getUserData(item.userID)).map((item: any) => { return item.userID; }) : [];
  const usersData = isThreadReady ? threadData.users.filter((item: any) => item.userID !== userData.userObject.userID && getUserData(item.userID)) : [];
  const allUsers = isThreadReady ? threadData.users.filter((item: any) => getUserData(item.userID)).map((item: any) => { return item.userID; }) : [];
  const allUsersData = isThreadReady ? threadData.users : [];
  const userThreadData = isThreadReady ? (threadData.users.filter((item: any) => item.userID === userData.userObject.userID).length === 0 ? {} : threadData.users.find((item: any) => item.userID === userData.userObject.userID)) : {};
  const isThreadAuthor = isThreadReady ? userData.userObject.userID === threadData.authorID : false;
  const isGroup = isThreadReady ? users.length > 1 : false;
  const isThreadNotificationsEnabled = isThreadReady ? userThreadData.notificationsEnabled : false;
  const isThreadUnread = isThreadReady ? userThreadData.unread : false;
  const isThreadArchived = isThreadReady ? threadData.archived : false;
  const dataUsers = allUsers.length === 1 ? allUsers : users;

  const queryString = getQueryString(location);
  const customViewMode = communicationData.viewMode;
  const drafts = communicationData.drafts;
  const isDraftExist = drafts.filter((item: any) => item.threadID === threadID).length === 1;
  const draftText = isDraftExist ? drafts.find((item: any) => item.threadID === threadID).value : "";

  const handleNavigate = (loc: any) => {
    if(customViewMode) {
      if(loc.includes("/")) {
        const getLoc = loc.split("/")[0];
        const thrdID = loc.split("/")[1];
        dispatch(setCommunicationThreadID(thrdID));
        dispatch(setCommunicationViewMode(getLoc));
      } else {
        dispatch(setCommunicationThreadID(null));
        dispatch(setCommunicationViewMode(loc));
      }
    } else {
      navigate(`/communication/${loc}${queryString}`);
    }
  };

  const getGroupClass = (count: any) => {
    if(count === 1) return classes.singleGroup;
    else if(count === 2) return classes.smallGroup;
    else if(count === 3) return classes.mediumGroup;
    else if(count === 4) return classes.largeGroup;
    else return classes.bigGroup;
  };

  const getThreadName = () => {
    if((threadData.name && threadData.name.length !== 0)) {
      return threadData.name;
    } else {
      return getUserData(dataUsers[0]).displayName;
    }
  };

  const getThreadText = () => {
    return threadData.lastMessage ? threadData.lastMessage.text : "";
  };

  const getThreadTextDate = () => {
    return threadData.lastMessage ? threadData.lastMessage.createdDate : moment();
  };

  const getThreadTextAuthorID = () => {
    return threadData.lastMessage ? threadData.lastMessage.authorID : 0;
  };

  const getIsUnread = () => {
    const lastMessageDate = threadData.lastMessage ? threadData.lastMessage.createdDate : moment();
    const lastMessageAuthor = threadData.lastMessage ? threadData.lastMessage.authorID : moment();
    const lastSeenDate = allUsersData.filter((item: any) => item.userID === userData.userObject.userID).length === 1 ? allUsersData.find((item: any) => item.userID === userData.userObject.userID).lastSeenDate : null;
    const result = isThreadUnread ? true : (lastSeenDate ? (lastMessageAuthor === userData.userObject.userID ? false : moment(lastSeenDate).isBefore(moment(lastMessageDate))) : true);
    return result;
  };

  const handleOpen = () => {
    if(isLoadingMessages) return;
    if(currentThreadID === threadID) {
      handleNavigate("threads");
    } else {
      handleNavigate(`thread/${threadID}`);
    }
  };

  const handleDropdownClick = (status: any) => {
    setState("isThreadActive", status);
  };

  const handleToggleNotifications = () => {
    const settings = {
      notificationsEnabled: !isThreadNotificationsEnabled,
    };
    communicationService && communicationService.settingsThread(threadID, settings).then((result: any) => {
      if(result) {
        if(result.status) {
          const response = result.data;
          const newData = {
            threadID: threadID,
            data: response,
          };
          dispatch(updateThreadData(newData));
          createNotification(settings.notificationsEnabled ? t("community_thread_unmuted") : t("community_thread_muted"), "success");
        } else {
          createNotification(settings.notificationsEnabled ? t("community_thread_failed_unmute") : t("community_thread_failed_mute"), "error");
        }
      } else {
        createNotification(settings.notificationsEnabled ? t("community_thread_failed_unmute") : t("community_thread_failed_mute"), "error");
      }
    }).catch(() => {
      createNotification(settings.notificationsEnabled ? t("community_thread_failed_unmute") : t("community_thread_failed_mute"), "error");
    });
  };
  const handleToggleReadStatus = () => {
    const settings = {
      unread: !isThreadUnread,
    };
    if(settings.unread && threadID === currentThreadID) {
      handleNavigate("threads");
    }
    communicationService && communicationService.settingsThread(threadID, settings).then((result: any) => {
      if(result) {
        if(result.status) {
          const response = result.data;
          const newData = {
            threadID: threadID,
            data: response,
          };
          dispatch(updateThreadData(newData));
          createNotification(settings.unread ? t("community_thread_mark_unread") : t("community_thread_mark_read"), "success");
        } else {
          createNotification(settings.unread ? t("community_thread_failed_unread") : t("community_thread_failed_read"), "error");
        }
      } else {
        createNotification(settings.unread ? t("community_thread_failed_unread") : t("community_thread_failed_read"), "error");
      }
    }).catch(() => {
      createNotification(settings.unread ? t("community_thread_failed_unread") : t("community_thread_failed_read"), "error");
    });
  };

  const handleArchiveThread = () => {

    const handleArchive = () => {
      const settings = {
        archived: !isThreadArchived,
      };
      communicationService && communicationService.updateThread(threadID, settings).then((result: any) => {
        if(result) {
          if(result.data) {
            const response = result.data;
            const newData = {
              threadID: threadID,
              data: response,
            };
            dispatch(updateThreadData(newData));
            createNotification(settings.archived ? t("community_thread_archived") : t("community_thread_unarchived"), "success");
          } else {
            createNotification(settings.archived ? t("community_thread_failed_archive") : t("community_thread_failed_unarchive"), "error");
          }
        } else {
          createNotification(settings.archived ? t("community_thread_failed_archive") : t("community_thread_failed_unarchive"), "error");
        }
      }).catch(() => {
        createNotification(settings.archived ? t("community_thread_failed_archive") : t("community_thread_failed_unarchive"), "error");
      });
    };

    const settings = {
      isOpen: true,
      title: isThreadArchived ? t('communication_thread_unarchive') : t('communication_thread_archive'),
      content: isThreadArchived ? t('communication_thread_unarchive_confirm') : t('communication_thread_archive_confirm'),
      onAccept: handleArchive,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const handleLeaveThread = () => {

    const handleLeave= () => {
      communicationService && communicationService.leaveThread(threadID).then((result: any) => {
        if(result) {
          if(result.status === 204) {
            dispatch(deleteThreadData(threadID));
            if(currentThreadID === threadID) {
              handleNavigate("threads");
            }
            createNotification(t("community_thread_leaved"), "success");
          } else {
            createNotification(t("community_thread_failed_leave"), "error");
          }
        } else {
          createNotification(t("community_thread_failed_leave"), "error");
        }
      }).catch(() => {
        createNotification(t("community_thread_failed_leave"), "error");
      });
    };

    const settings = {
      isOpen: true,
      title: t('communication_thread_leave'),
      content: t('communication_thread_leave_confirm'),
      onAccept: handleLeave,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const handleDeleteThread = () => {

    const handleDelete = () => {
      communicationService && communicationService.deleteThread(threadID).then((result: any) => {
        if(result) {
          if(result.status === 204) {
            dispatch(deleteThreadData(threadID));
            if(currentThreadID === threadID) {
              handleNavigate("threads");
            }
            createNotification(t("community_thread_deleted"), "success");
          } else {
            createNotification(t("community_thread_failed_delete"), "error");
          }
        } else {
          createNotification(t("community_thread_failed_delete"), "error");
        }
      }).catch(() => {
        createNotification(t("community_thread_failed_delete"), "error");
      });
    };

    const settings = {
      isOpen: true,
      title: t('communication_thread_delete'),
      content: t('communication_thread_delete_confirm'),
      onAccept: handleDelete,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const { longPressProps } = useLongPress({
    onLongPress: (e: any) => {  
      setIsOpen(e.target);
    }
  });

  const setIsOpen = (value: any) => {
    setState("currentTarget", value);
  };

  const dropdownMenuItems = [
    isThreadUnread ? {
      title: t('communication_thread_mark_read'),
      icon: (<SVG src="eye"/>),
      onClick: handleToggleReadStatus,
      dataCy: '',
    } : {
      title: t('communication_thread_mark_unread'),
      icon: (<SVG src="eye-slash"/>),
      onClick: handleToggleReadStatus,
      dataCy: '',
    },
    isThreadNotificationsEnabled ? {
      title: t('communication_thread_mute'),
      icon: (<SVG src="bell-outlined"/>),
      onClick: handleToggleNotifications,
      dataCy: '',
    } : {
      title: t('communication_thread_unmute'),
      icon: (<SVG src="bell-cancel-outlined"/>),
      onClick: handleToggleNotifications,
      dataCy: '',
    },
    isThreadAuthor ? {
      title: isThreadArchived ? t('communication_thread_unarchive') : t('communication_thread_archive'),
      icon: (<SVG src="archive"/>),
      onClick: handleArchiveThread,
      dataCy: '',
    } : null,
    !isThreadAuthor ? {
      title: t('communication_thread_leave'),
      icon: (<SVG src="leave"/>),
      onClick: handleLeaveThread,
      dataCy: '',
    } : null,
    isThreadAuthor ? {
      title: t('communication_thread_delete'),
      icon: (<SVG src="trash"/>),
      onClick: handleDeleteThread,
      dataCy: '',
    } : null,
  ].filter((item: any) => item !== null);

  const classes = useStyles({
    currentThreadID: currentThreadID === threadID,
    isThreadActive: state.isThreadActive,
    isLoadingMessages: isLoadingMessages,
    isUnread: getIsUnread(),
    isMobile: isMobile,
  });

  return allUsers.length > 0 ? (
    <ContextMenuTrigger id={`thread_${threadID}`} className={classes.threadItemWrapper} renderTag={"div"} dataCy={`thread${threadID}`} disable={isMobile || !getUserSetting(userData.userSettings, "addons", ["communication", "communication_thread_context_menu"])}>
      <div {...mergeProps(isMobile ? longPressProps : null)} className={classes.threadItem} onClick={handleOpen}>
        <ContextMenu id={`thread_${threadID}`} preventHideOnScroll={false} hideOnLeave={true}>
          {
            isThreadUnread ? (
              <ContextMenuItem onClick={handleToggleReadStatus}>
                <SVG src="eye"/>
                {t('communication_thread_mark_read')}
              </ContextMenuItem>
            ) : (
              <ContextMenuItem onClick={handleToggleReadStatus}>
                <SVG src="eye-slash"/>
                {t('communication_thread_mark_unread')}
              </ContextMenuItem>
            )
          }
          {
            isThreadNotificationsEnabled ? (
              <ContextMenuItem onClick={handleToggleNotifications}>
                <SVG src="bell-outlined"/>
                {t('communication_thread_mute')}
              </ContextMenuItem>
            ) : (
              <ContextMenuItem onClick={handleToggleNotifications}>
                <SVG src="bell-cancel-outlined"/>
                {t('communication_thread_unmute')}
              </ContextMenuItem>
            )
          }
          {
            isThreadAuthor ? (
              <ContextMenuItem onClick={handleArchiveThread}>
                <SVG src="archive"/>
                {isThreadArchived ? t('communication_thread_unarchive') : t('communication_thread_archive')}
              </ContextMenuItem>
            ) : null
          }
          {
            !isThreadAuthor ? (
              <ContextMenuItem onClick={handleLeaveThread}>
                <SVG src="leave"/>
                {t('communication_thread_leave')}
              </ContextMenuItem>
            ) : null
          }
          {
            isThreadAuthor ? (
              <ContextMenuItem onClick={handleDeleteThread}>
                <SVG src="trash"/>
                {t('communication_thread_delete')}
              </ContextMenuItem>
            ) : null
          }
        </ContextMenu>
        <div className={classes.info}>
          <div className={classes.avatars}>
            <div className={getGroupClass(dataUsers.length)}>
              {
                dataUsers.slice(0, dataUsers.length > 4 ? 3 : 4).map((item: any, key: any) => {
                  const userData = getUserData(item);
                  return userData ? (
                    <AuthenticatedImage className={classes.avatar} thumbLink={userData.photo.thumbLink} key={`k_${key}`}/>
                  ) : null;
                })
              }
              {
                dataUsers.length > 4 ? (
                  <span>
                    +{dataUsers.length - 3}
                  </span>
                ) : null
              }
            </div>
          </div>
          <div className={classes.detail}>
            <span>{getThreadName()}</span>
            <div>
              <p>
                {
                  (isDraftExist && currentThreadID !== threadID) ? (
                    <>
                      <span>{`${t('communication_message_draft')}: `}</span>{draftText}
                    </>
                  ) : (
                    <>
                      {`${getThreadTextAuthorID() === userData.userObject.userID ? `${t('communication_message_me')}: ` : ''}${getThreadText()}`}
                    </>
                  )
                }
              </p>
              <span>
                <RelativeDate date={getThreadTextDate()}/>
              </span>
            </div>
          </div>
          <div>
            <DropdownWithMenu isOpen={state.currentTarget} setIsOpen={setIsOpen} className={classes.dropdownMenu} items={dropdownMenuItems} classNamePopover={classes.dropdownMenuPopover} classNameMenuButton={classes.dropdownMenuButton} classNameMoreButton={classes.moreButton} moreButtonSize='small' position='bottom-center' onClick={handleDropdownClick} popoverArrow/>
          </div>
        </div>
        <div className={classes.status}>
          {
            !isThreadNotificationsEnabled ? (
              <SVG src="bell-cancel"/>
            ) : (
              <>
                {
                  getIsUnread() ? (
                    <span>
                      <SVG src="circle"/>
                    </span>
                  ) : null
                }
                {
                  (!isGroup && getThreadTextAuthorID() === userData.userObject.userID) ? (
                    <>
                      {
                        usersData.filter((item: any) => item.lastSeenDate && moment(item.lastSeenDate).isSameOrAfter(moment(getThreadTextDate()))).map((item: any, key: any) => {
                          const userAvatar = getUserData(item.userID) ? getUserData(item.userID).photo.thumbLink : "";
                          return (
                            <AuthenticatedImage className={classes.viewedAvatar} thumbLink={userAvatar} key={`k_${key}`}/>
                          );
                        }) 
                      }
                    </>
                  ) : null
                }
              </>
            )
          }
        </div>
      </div>
    </ContextMenuTrigger>
  ) : null;
};

export default ThreadItem;