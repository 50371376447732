import addons from 'src/constants/addons';
import IconButton from 'src/components/Buttons/IconButton';
import React, { useCallback, useRef } from 'react';
import Select from 'src/components/Forms/Select';
import SVG from 'src/components/Images/SvgRenderer';
import Switch from 'src/components/Forms/Switch';
import TooltipIcon from 'src/components/Icons/TooltipIcon';
import useBreakpoint from 'src/utils/useBreakpoint';
import { Button } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole, getUserSetting, saveUserSettings, updateUserSetting } from 'src/utils/useUser';
import { setCommunicationThreadID, setCommunicationViewMode } from 'src/store/actions/communication.actions';
import { setUserSettings } from 'src/store/actions/user.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useLocation, useNavigate } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { getQueryString } from 'src/utils/useFunctions';

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    backgroundColor: theme.colors.white,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: 'calc(100% - 24px)',
    padding: '12px',
    paddingTop: '12px',
    '& > span': {
      fontSize: '24px',
      fontWeight: '600',
    },
    '& > button': {
      width: '36px',
      height: '36px',
      backgroundColor: theme.colors.white,
      color: theme.colors.primaryBlue[500],
      zIndex: '1',
      '&:hover': {
        backgroundColor: theme.colors.grey[125],
      },
      '& > svg': {
        width: '100%',
        height: '100%',
        color: 'inherit',
      },
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '12px',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '8px',
    width: 'calc(100% - 24px)',
    padding: '12px',
    paddingTop: '12px',
    '&:not(:last-of-type)': {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.colors.grey[325],
    },
    '& > span': {
      display: 'flex',
      gap: '8px',
      fontSize: '20px',
      fontWeight: '500',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& > button': {
        textTransform: 'unset',
        color: theme.colors.primaryBlue[500],
        backgroundColor: theme.colors.grey[100],
        borderRadius: '12px',
        fontSize: '12px',
      }, 
    },
  },
  settingsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    backgroundColor: theme.colors.chip,
    maxWidth: '100%',
    width: '100%',
  },
  setting: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.colors.white,
    padding: '8px 0',
    gap: '8px',
    '& > svg': {
      width: '32px',
      height: '32px',
      color: theme.colors.grey[800],
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    gap: '16px',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    '& > span': {
      display: 'flex',
      fontSize: '16px',
      width: '100%',
      alignItems: 'center',
      gap: '6px',
      '& > em': {
        display: 'inline-flex',
        height: 'fit-content',
        fontStyle: 'unset',
        fontSize: '9px',
        padding: '3px 5px',
        borderRadius: '6px',
        backgroundColor: theme.colors.primaryBlue[500],
        color: theme.colors.white,
      },
      '& > span': {
        lineHeight: '1',
        '& > svg': {
          width: '16px',
          height: '16px',
        },
      },
    },
  },
  switch: {
    width: 'fit-content',
    transform: 'scale(1.25)',
  },
  select: {
    flex: 'unset',
    width: '100%',
  },
}));

const Settings: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const communicationData = useAppSelector((state: any) => state.communication);
  const userData = useAppSelector((state: any) => state.user);
  const classes = useStyles();

  const isSaving = useRef(false);

  const refreshTimes = useCallback(() => [
    {
      value: '0',
      name: t('never'),
    },
    {
      value: '30000',
      name: `30 ${t('seconds_more').toLowerCase()}`,
    },
    {
      value: '60000',
      name: `1 ${t('minute').toLowerCase()}`,
    },
    {
      value: '90000',
      name: `1.5 ${t('minutes').toLowerCase()}`,
    },
    {
      value: '120000',
      name: `2 ${t('minutes').toLowerCase()}`,
    },
    {
      value: '180000',
      name: `3 ${t('minutes').toLowerCase()}`,
    },
    {
      value: '300000',
      name: `5 ${t('minutes_more').toLowerCase()}`,
    },
  ], [t]);

  const autoRefreshThreads = getUserSetting(userData.userSettings, "customizations", ["communication", "autorefresh_threads"]);
  const autoRefreshMessages = getUserSetting(userData.userSettings, "customizations", ["communication", "autorefresh_messages"]);

  const [state, setState] = useStates({
    isSaving: false,
    autoRefreshThreads: refreshTimes().filter((item: any) => item.value === autoRefreshThreads.toString()).length === 0 ? refreshTimes()[2] : refreshTimes().find((item: any) => item.value === autoRefreshThreads.toString()),
    autoRefreshMessages: refreshTimes().filter((item: any) => item.value === autoRefreshMessages.toString()).length === 0 ? refreshTimes()[2] : refreshTimes().find((item: any) => item.value === autoRefreshMessages.toString()),
  });

  const breakpoint: any = useBreakpoint();

  const layouts: any = {
    xxxxl: "desktop",
    xxxl: "desktop",
    xxl: "desktop",
    xl: "desktop",
    lg: "desktop",
    bg: "desktop",
    md: "mobile",
    co: "mobile",
    sm: "mobile",
    xs: "mobile",
  };

  const queryString = getQueryString(location);
  const customViewMode = communicationData.viewMode;
  const layout = customViewMode ? "mobile" : layouts[breakpoint];

  const curAddon = "communication";

  const addonsList: any = addons.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));
  const currentAddon = addonsList.filter((item: any) => item.key === curAddon).length > 0 ? addonsList.find((item: any) => item.key === curAddon) : [];
  const addonItems = currentAddon.items.filter((item: any) => item.isEnabled && item.isSetup && item.roles.includes(getUserRole(userData.userObject.roleType)));

  const handleNavigate = (loc: any) => {
    if(customViewMode) {
      dispatch(setCommunicationThreadID(null));
      dispatch(setCommunicationViewMode(loc));
    } else {
      navigate(`/communication/${loc}${queryString}`);
    }
  };

  const handleSwitch = async (name: any, value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      setState("isSaving", true);
      const oldValue = getUserSetting(userData.userSettings, "addons", [currentAddon.key, name]);
      const newValue = updateUserSetting(userData.userSettings, "addons", [currentAddon.key, name], value);
      dispatch(setUserSettings(newValue));
      const result = await saveUserSettings(dispatch, userData, "addons", [currentAddon.key, name], value);
      if(result) {
        isSaving.current = false;
        setState("isSaving", false);
      } else {
        const updateSettings = updateUserSetting(userData.userSettings, "addons", [currentAddon.key, name], oldValue);
        dispatch(setUserSettings(updateSettings));
        createNotification(t("user_settings_not_saved"), "error");
        isSaving.current = false;
        setState("isSaving", false);
      }
    }
  };

  const handleAutoRefreshThreads = async (value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      setState("isSaving", true);
      const newValue = parseInt(value.value);
      const result = await saveUserSettings(dispatch, userData, "customizations", ["communication", "autorefresh_threads"], newValue);
      if(result) {
        setState("autoRefreshThreads", value);
        isSaving.current = false;
        setState("isSaving", false);
      } else {
        createNotification(t("user_settings_not_saved"), "error");
        isSaving.current = false;
        setState("isSaving", false);
      }
    }
  };

  const handleAutoRefreshMessages = async (value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      setState("isSaving", true);
      const newValue = parseInt(value.value);
      const result = await saveUserSettings(dispatch, userData, "customizations", ["communication", "autorefresh_messages"], newValue);
      if(result) {
        setState("autoRefreshMessages", value);
        isSaving.current = false;
        setState("isSaving", false);
      } else {
        createNotification(t("user_settings_not_saved"), "error");
        isSaving.current = false;
        setState("isSaving", false);
      }
    }
  };

  const handleBackToThreads = () => {
    handleNavigate("threads");
  };

  const handleGoSettingsAddons = () => {
    navigate("/settings/addons/communication");
  };

  const handleGoSettingsCustomizations = () => {
    navigate("/settings/customizations/communication");
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        {
          layout === "mobile" ? (
            <IconButton tooltip={t('back')} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={handleBackToThreads}>
              <SVG src="arrow-left"/>
            </IconButton>
          ) : null
        }
        <span>{t('communication_settings')}</span>
      </div>
      <div className={classes.section}>
        <span>{t('customizations')}<Button onClick={handleGoSettingsCustomizations}>{t('go_to_settings')}</Button></span>
        <Select className={classes.select} label={t('communication_auto_refresh_threads')}  items={refreshTimes()} selected={state.autoRefreshThreads} setSelected={handleAutoRefreshThreads} allowClear={false} disabled={state.isSaving}/>
        <Select className={classes.select} label={t('communication_auto_refresh_messages')}  items={refreshTimes()} selected={state.autoRefreshMessages} setSelected={handleAutoRefreshMessages} allowClear={false} disabled={state.isSaving}/>
      </div>
      <div className={classes.section}>
        <span>{t('addons')}<Button onClick={handleGoSettingsAddons}>{t('go_to_settings')}</Button></span>
        <div className={classes.settingsWrapper}>
          {
            addonItems.map((item: any, key: any) => (
              <div className={classes.setting} key={`k_${key}`}>
                {
                  item.icon ? (
                    <SVG src={item.icon}/>
                  ) : null
                }
                <div className={classes.content}>
                  <div className={classes.row}>
                    <span>
                      <span>{t(item.name)}</span>
                      <TooltipIcon title={t(item.desc)} maxWidth={400}/>
                      {
                        item.isRecommended ? (
                        <em>{t('recommended_by_twigsee')}</em>
                        ) : null
                      }
                    </span>
                  </div>
                  <Switch className={classes.switch} name={item.key} checked={getUserSetting(userData.userSettings, "addons", [currentAddon.key, item.key])} onChange={(name: any, val: any) => handleSwitch(name, val)} disabled={state.isSaving}/>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default Settings;