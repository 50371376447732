import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import CommunicationThreadGallery from 'src/components/Gallery/CommunicationThreadGallery';
import DropdownWithMenu from 'src/components/Buttons/DropdownWithMenu';
import IconButton from 'src/components/Buttons/IconButton';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import useBreakpoint from 'src/utils/useBreakpoint';
import UsersSelect from 'src/components/Selects/UsersSelect';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { deleteThreadData, setCommunicationUserID, setCommunicationViewMode, updateThreadData } from 'src/store/actions/communication.actions';
import { getQueryString, getSchoolSettings } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { setCommunicationChangeThreadNameModal, setConfirmModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useLocation, useNavigate } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 25%',
    backgroundColor: theme.colors.white,
    maxHeight: '100%',
    gap: '16px',
    overflow: 'auto',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      flex: '0 0 100%',
    },
    '&.mobile': {
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      flex: '0 0 100%',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    position: 'sticky',
    top: '0px',
    left: '0px',
    width: 'calc(100% - 16px)',
    padding: '10px 8px',
    backgroundColor: theme.colors.white,
    zIndex: '2',
    '& > button': {
      width: '36px',
      height: '36px',
      backgroundColor: theme.colors.white,
      color: theme.colors.primaryBlue[500],
      zIndex: '1',
      '&:hover': {
        backgroundColor: theme.colors.grey[125],
      },
      '& > svg': {
        width: '100%',
        height: '100%',
        color: 'inherit',
      },
    },
    '& > span': {
      color: theme.colors.black,
      textTransform: 'unset',
      fontSize: '14px',
      fontWeight: '500',
    },
  },
  basicInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    width: 'calc(100% - 16px)',
    padding: '24px 8px 0px 8px',
    '& > span': {
      fontSize: '14px',
      fontWeight: '500',
      display: 'block',
      maxWidth: '50%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  bigAvatars: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '96px',
    '& > div': {
      transform: 'scale(2)',
    },
  },
  avatars: {
    display: 'flex',
    alignItems: 'center',
    width: '48px',
    height: '48px',
  },
  singleGroup: {
    display: 'flex',
    alignItems: 'center',
    width: '48px',
    height: '48px',
  },
  smallGroup: {
    position: 'relative',
    display: 'flex',
    width: '48px',
    borderRadius: '0%',
    '& > div': {
      position: 'absolute',
      width: '32px',
      height: '32px',
      borderRadius: '8px',
      '&:first-of-type': {
        top: 'calc(100% - 24px)',
        left: '0px',
      },
      '&:last-of-type': {
        bottom: 'calc(100% - 24px)',
        right: '0px',
      },
    },
  },
  mediumGroup: {
    position: 'relative',
    display: 'flex',
    width: '48px',
    borderRadius: '0%',
    '& > div': {
      position: 'absolute',
      width: '26px',
      height: '26px',
      borderRadius: '6px',
      '&:nth-child(1)': {
        top: 'calc(100% - 26px)',
        left: '11px',
      },
      '&:nth-child(2)': {
        bottom: 'calc(100% - 20px)',
        left: '0px',
      },
      '&:nth-child(3)': {
        bottom: 'calc(100% - 20px)',
        right: '0px',
      },
    },
  },
  largeGroup: {
    position: 'relative',
    display: 'flex',
    width: '48px',
    borderRadius: '0%',
    '& > div': {
      position: 'absolute',
      width: '24px',
      height: '24px',
      borderRadius: '6px',
      '&:nth-child(1)': {
        bottom: 'calc(100% - 24px)',
        left: '0px',
      },
      '&:nth-child(2)': {
        bottom: 'calc(100% - 24px)',
        right: '0px',
      },
      '&:nth-child(3)': {
        top: 'calc(100% - 24px)',
        left: '0px',
      },
      '&:nth-child(4)': {
        top: 'calc(100% - 24px)',
        right: '0px',
      },
    },
  },
  bigGroup: {
    position: 'relative',
    display: 'flex',
    width: '48px',
    borderRadius: '0%',
    '& > div': {
      position: 'absolute',
      width: '24px',
      height: '24px',
      borderRadius: '6px',
      '&:nth-child(1)': {
        bottom: 'calc(100% - 24px)',
        left: '0px',
      },
      '&:nth-child(2)': {
        bottom: 'calc(100% - 24px)',
        right: '0px',
      },
      '&:nth-child(3)': {
        top: 'calc(100% - 24px)',
        left: '0px',
      },
    },
    '& > span': {
      position: 'absolute',
      top: 'calc(100% - 23.5px)',
      right: '0.5px',
      width: '23px',
      height: '23px',
      backgroundColor: theme.colors.grey[325],
      color: theme.colors.black,
      borderRadius: '6px',
      fontSize: '70%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  avatar: {
    position: 'relative',
    width: '46px',
    height: '46px',
    borderRadius: '12px',
    '& > div': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[325],
      borderRadius: 'inherit',
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '8px',
    width: 'calc(100% - 16px)',
    padding: '0px 8px',
    '& > div': {
      '& > button': {
        width: '36px',
        height: '36px',
        backgroundColor: theme.colors.grey[200],
        color: theme.colors.black,
        '&:hover': {
          backgroundColor: theme.colors.grey[300],
        },
        '& > svg': {
          width: '100%',
          height: '100%',
          color: 'inherit',
        },
      },
    },
  },
  accordions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: 'calc(100% - 16px)',
    padding: '0px 8px',
  },
  accordion: {
    width: '100%',
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    overflow: 'hidden',
    margin: '0 !important',
    borderRadius: '12px !important',
    '&.Mui-disabled': {
      '& .MuiAccordionSummary-expandIconWrapper': {
        display: 'none',
      },
    },
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    backgroundColor: theme.colors.white,
    padding: '0px 8px',
    alignItems: 'center',
    gap: '8px',
    minHeight: 'unset !important',
    opacity: '1 !important',
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      margin: '12px 0',
      '& > span': {
        fontSize: '16px',
        fontWeight: '600',
      },
      '& > p': {
        fontSize: '14px',
        marginLeft: 'auto',
      },
    },
    '& > .MuiAccordionSummary-expandIconWrapper': {
      '& > svg': {
        width: '24px',
        height: '24px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px 4px',
    },
  },
  accordionDetails: {
    padding: '0px',
    backgroundColor: theme.colors.white,
  },
  customizeButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    gap: '8px',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    textTransform: 'unset',
    fontSize: '14px',
    borderRadius: '14px',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: '32px',
      backgroundColor: theme.colors.grey[200],
      borderRadius: '100%',
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  userButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    gap: '8px',
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    textTransform: 'unset',
    fontSize: '14px',
    borderRadius: '14px',
    padding: '6px 8px',
    maxWidth: 'calc(100% - 16px)',
    '& > div': {
      '&:first-of-type': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '32px',
        minWidth: '32px',
        maxWidth: '32px',
        height: '32px',
        minHeight: '32px',
        maxHeight: '32px',
        backgroundColor: theme.colors.grey[200],
        borderRadius: '12px',
        position: 'relative',
      },
    },
    '& > span': {
      '&:first-of-type': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        '& > span': {
          display: 'block',
          maxWidth: '80%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '& > p': {
          fontSize: '12px',
          color: theme.colors.grey[500],
          lineHeight: '12px',
        },
      },
    },
  },
  dropdownMenu: {
    position: 'relative',
  },
  dropdownMenuPopover: {
    borderRadius: '14px',
  },
  dropdownMenuButton: {
    backgroundColor: theme.colors.grey[100],
  },
  moreButton: {
    backgroundColor: theme.colors.grey[200],
    '&:disabled': {
      color: theme.colors.grey[560],
      backgroundColor: theme.colors.grey[75],
    },
    '& > svg': {
      width: '14px',
      height: '14px',
    },
  },
  gallery: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    zIndex: '1',
    height: 'calc(100% - 74px)',
    maxHeight: 'calc(100% - 74px)',
    overflow: 'auto',
  },
}));

type SidebarType = {
  threadID: any;
  messages: any;
  setMessages: any;
  onSearchToggle: any;
  onSidebarToggle: any;
};

const Sidebar: React.FunctionComponent<SidebarType> = ({ threadID, messages, setMessages, onSearchToggle, onSidebarToggle }) => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const communicationData = useAppSelector((state: any) => state.communication);
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);
  const communicationService = useAppSelector((state: any) => state.services).communicationService;

  const maxUsers = userData.threadLimits["communication"].maxUsers;
  const userAccessSchools = userData.userAccessSchools;
  const schoolSettings = userData.schoolSettings;

  const queryString = getQueryString(location);
  const breakpoint: any = useBreakpoint();

  const layouts: any = {
    xxxxl: "desktop",
    xxxl: "desktop",
    xxl: "desktop",
    xl: "desktop",
    lg: "desktop",
    bg: "desktop",
    md: "mobile",
    co: "mobile",
    sm: "mobile",
    xs: "mobile",
  };
  
  const customViewMode = communicationData.viewMode;
  const layout = customViewMode ? "mobile" : layouts[breakpoint];

  const [state, setState] = useStates({
    isUsersSelectOpen: false,
    section: "default",
  });

  const getUserData = useCallback((userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  }, [dataData.users]);

  const getThreadData = useCallback((threadID: any) => {
    return communicationData.list.filter((item: any) => item.threadID === threadID).length === 0 ? null : communicationData.list.find((item: any) => item.threadID === threadID);
  }, [communicationData.list]);

  const threadData = getThreadData(threadID);
  const isThreadReady = threadData && threadData.length !== 0;
  const users = isThreadReady ? threadData.users.filter((item: any) => item.userID !== userData.userObject.userID && getUserData(item.userID)).map((item: any) => { return item.userID; }) : [];
  const allUsers = isThreadReady ? threadData.users.filter((item: any) => getUserData(item.userID)).map((item: any) => { return item.userID; }) : [];
  const userThreadData = isThreadReady ? (threadData.users.filter((item: any) => item.userID === userData.userObject.userID).length === 0 ? {} : threadData.users.find((item: any) => item.userID === userData.userObject.userID)) : {};
  const isThreadAuthor = isThreadReady ? userData.userObject.userID === threadData.authorID : false;
  const isThreadNotificationsEnabled = isThreadReady ? userThreadData.notificationsEnabled : false;
  const isThreadArchived = isThreadReady ? threadData.archived : false;
  const schoolID = isThreadReady ? threadData.schoolID : 0;
  const dataUsers = allUsers.length === 1 ? allUsers : users;

  const availableUsers = dataData.users.filter((item: any) => !users.includes(item.userID) && item.userID !== userData.userObject.userID);
  const modules = getSchoolSettings(schoolID, 'modules', schoolSettings) === null ? 1 : getSchoolSettings(schoolID, 'modules', schoolSettings);
  const parentToParent = modules ? (modules.communication ? (modules.communication.parentToParent ? modules.communication.parentToParent : false) : false) : false;
  const roles = getUserRole(userData.userObject.roleType) === "parent" ? (parentToParent ? [4, 2, 3] : [4, 2]) : [4, 2, 3];

  const getGroupClass = (count: any) => {
    if(count === 1) return classes.singleGroup;
    else if(count === 2) return classes.smallGroup;
    else if(count === 3) return classes.mediumGroup;
    else if(count === 4) return classes.largeGroup;
    else return classes.bigGroup;
  };

  const getThreadName = () => {
    if((threadData.name && threadData.name.length !== 0)) {
      return threadData.name;
    } else {
      return getUserData(dataUsers[0]).displayName;
    }
  };

  const getThreadRealName = () => {
    if((threadData.name && threadData.name.length !== 0)) {
      return threadData.name;
    } else {
      return '';
    }
  };

  const handleAddMessage = (value: any) => {
    const newMessages = [...messages, value];
    setMessages(newMessages);
  };

  const handleChangeThreadName = () => {
    const settings = {
      isOpen: true,
      threadID: threadID,
      threadName: getThreadRealName(),
      onChange: handleAddMessage,
    };
    dispatch(setCommunicationChangeThreadNameModal(settings));
  };

  const handleToggleNotifications = () => {
    const settings = {
      notificationsEnabled: !isThreadNotificationsEnabled,
    };
    communicationService && communicationService.settingsThread(threadID, settings).then((result: any) => {
      if(result) {
        if(result.status) {
          const response = result.data;
          const newData = {
            threadID: threadID,
            data: response,
          };
          dispatch(updateThreadData(newData));
          createNotification(settings.notificationsEnabled ? t("community_thread_unmuted") : t("community_thread_muted"), "success");
        } else {
          createNotification(settings.notificationsEnabled ? t("community_thread_failed_unmute") : t("community_thread_failed_mute"), "error");
        }
      } else {
        createNotification(settings.notificationsEnabled ? t("community_thread_failed_unmute") : t("community_thread_failed_mute"), "error");
      }
    }).catch(() => {
      createNotification(settings.notificationsEnabled ? t("community_thread_failed_unmute") : t("community_thread_failed_mute"), "error");
    });
  };

  const handleArchiveThread = () => {

    const handleArchive = () => {
      const settings = {
        archived: !isThreadArchived,
      };
      communicationService && communicationService.updateThread(threadID, settings).then((result: any) => {
        if(result) {
          if(result.data) {
            const response = result.data;
            const newData = {
              threadID: threadID,
              data: response,
            };
            dispatch(updateThreadData(newData));
            if(response.lastMessage) {
              const message = {...response.lastMessage, thread: { threadID: threadID} };
              handleAddMessage(message);
            }
            createNotification(settings.archived ? t("community_thread_archived") : t("community_thread_unarchived"), "success");
          } else {
            createNotification(settings.archived ? t("community_thread_failed_archive") : t("community_thread_failed_unarchive"), "error");
          }
        } else {
          createNotification(settings.archived ? t("community_thread_failed_archive") : t("community_thread_failed_unarchive"), "error");
        }
      }).catch(() => {
        createNotification(settings.archived ? t("community_thread_failed_archive") : t("community_thread_failed_unarchive"), "error");
      });
    };

    const settings = {
      isOpen: true,
      title: isThreadArchived ? t('communication_thread_unarchive') : t('communication_thread_archive'),
      content: isThreadArchived ? t('communication_thread_unarchive_confirm') : t('communication_thread_archive_confirm'),
      onAccept: handleArchive,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const handleDeleteThread = () => {

    const handleDelete = () => {
      communicationService && communicationService.deleteThread(threadID).then((result: any) => {
        if(result) {
          if(result.status === 204) {
            dispatch(deleteThreadData(threadID));
            navigate(`/communication/threads${queryString}`);
            createNotification(t("community_thread_deleted"), "success");
          } else {
            createNotification(t("community_thread_failed_delete"), "error");
          }
        } else {
          createNotification(t("community_thread_failed_delete"), "error");
        }
      }).catch(() => {
        createNotification(t("community_thread_failed_delete"), "error");
      });
    };

    const settings = {
      isOpen: true,
      title: t('communication_thread_delete'),
      content: t('communication_thread_delete_confirm'),
      onAccept: handleDelete,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const handleLeaveThread = () => {

    const handleLeave= () => {
      communicationService && communicationService.leaveThread(threadID).then((result: any) => {
        if(result) {
          if(result.status === 204) {
            dispatch(deleteThreadData(threadID));
            navigate("threads");
            createNotification(t("community_thread_leaved"), "success");
          } else {
            createNotification(t("community_thread_failed_leave"), "error");
          }
        } else {
          createNotification(t("community_thread_failed_leave"), "error");
        }
      }).catch(() => {
        createNotification(t("community_thread_failed_leave"), "error");
      });
    };

    const settings = {
      isOpen: true,
      title: t('communication_thread_leave'),
      content: t('communication_thread_leave_confirm'),
      onAccept: handleLeave,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const handleNavigate = (loc: any) => {
    if(customViewMode) {
      if(loc.includes("/")) {
        const getLoc = loc.split("/")[0];
        const usrID = loc.split("/")[1];
        dispatch(setCommunicationUserID(usrID));
        dispatch(setCommunicationViewMode(getLoc));
      } else {
        dispatch(setCommunicationUserID(null));
        dispatch(setCommunicationViewMode(loc));
      }
    } else {
      navigate(`/communication/${loc}`);
    }
  };

  const handleNewMessage = (userID: any, schoolID: any) => {
    handleNavigate(`new/${userID}-${schoolID}`);
  };

  const handleAddRecipients = () => {
    setState("isUsersSelectOpen", true);
  };

  const handleRecipients = (value: any) => {
    const settings = {
      addUsers: value.map((item: any) => { return item.userID; }),
    };
    if(settings.addUsers.length + allUsers.length > maxUsers) {
      createNotification(t('limit_exceeded_users_thread', { limit: maxUsers }), "error");
      return;
    }
    communicationService && communicationService.updateThread(threadID, settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data;
          const newData = {
            threadID: threadID,
            data: response,
          };
          dispatch(updateThreadData(newData));
          if(response.lastMessage) {
            const message = {...response.lastMessage, thread: { threadID: threadID} };
            handleAddMessage(message);
          }
          createNotification(settings.addUsers.length > 1 ? t("community_thread_users_added") : t("community_thread_user_added"), "success");
        } else {
          createNotification(settings.addUsers.length > 1 ? t("community_thread_failed_add_users") : t("community_thread_failed_add_user"), "error");
        }
      } else {
        createNotification(settings.addUsers.length > 1 ? t("community_thread_failed_add_users") : t("community_thread_failed_add_user"), "error");
      }
    }).catch(() => {
      createNotification(settings.addUsers.length > 1 ? t("community_thread_failed_add_users") : t("community_thread_failed_add_user"), "error");
    });
  };

  const handleRemoveRecipient = (userID: any) => {

    const handleRemove = () => {
      const settings = {
        deleteUsers: [userID],
      };
      communicationService && communicationService.updateThread(threadID, settings).then((result: any) => {
        if(result) {
          if(result.data) {
            const response = result.data;
            const newData = {
              threadID: threadID,
              data: response,
            };
            dispatch(updateThreadData(newData));
            if(response.lastMessage) {
              const message = {...response.lastMessage, thread: { threadID: threadID} };
              handleAddMessage(message);
            }
            createNotification(t("community_thread_user_removed"), "success");
          } else {
            createNotification(t("community_thread_failed_remove_user"), "error");
          }
        } else {
          createNotification(t("community_thread_failed_remove_user"), "error");
        }
      }).catch(() => {
        createNotification(t("community_thread_failed_remove_user"), "error");
      });
    };

    const settings = {
      isOpen: true,
      title: t('communication_remove_user'),
      content: t('communication_remove_user_confirm'),
      onAccept: handleRemove,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const handleGoDefault = () => {
    setState("section", "default");
  };

  const handleGoMedia = () => {
    setState("section", "media");
  };

  const handleGoAttachments = () => {
    setState("section", "attachments");
  };

  return (
    <div className={`${classes.sidebar} ${layout}`}>
      {
        state.section === "default" ? (
          <>
            {
              layout === "mobile" ? (
                <div className={classes.header}>
                  <IconButton tooltip={t('back')} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={onSidebarToggle}>
                    <SVG src="arrow-left"/>
                  </IconButton>
                </div>
              ) : null
            }
            <div className={classes.basicInfo}>
              <div className={classes.bigAvatars}>
                <div className={classes.avatars}>
                  <div className={getGroupClass(dataUsers.length)}>
                    {
                      dataUsers.slice(0, dataUsers.length > 4 ? 3 : 4).map((item: any, key: any) => {
                        const userData = getUserData(item);
                        return userData ? (
                          <AuthenticatedImage className={classes.avatar} thumbLink={userData.photo.thumbLink} key={`k_${key}`}/>
                        ) : null;
                      })
                    }
                    {
                      dataUsers.length > 4 ? (
                        <span>
                          +{dataUsers.length - 3}
                        </span>
                      ) : null
                    }
                  </div>
                </div>
              </div>
              <span>
                {getThreadName()}
              </span>
            </div>
            <div className={classes.actions}>
              <IconButton label={isThreadNotificationsEnabled ? t('mute') : t('unmute')} onClick={handleToggleNotifications}>
                <SVG src={isThreadNotificationsEnabled ? "bell-outlined" : "bell-cancel-outlined"}/>
              </IconButton>
              <IconButton label={t('search')} onClick={onSearchToggle}>
                <SVG src="search"/>
              </IconButton>
            </div>
            <div className={classes.accordions}>
              <Accordion className={classes.accordion} defaultExpanded={true} data-cy={isCypress() ? 'communicationThreadSettings' : null}>
                <AccordionSummary className={classes.accordionSummary} expandIcon={<SVG src="chevron-down"/>}>
                  <span>{t('communication_thread_settings')}</span>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  {
                    isThreadAuthor ? (
                      <>
                        <Button className={classes.customizeButton} disabled={!isThreadAuthor || isThreadArchived} onClick={handleChangeThreadName}>
                          <div><SVG src="pencil"/></div>
                          {t('communication_thread_rename')}
                        </Button>
                        <Button className={classes.customizeButton} disabled={!isThreadAuthor} onClick={handleArchiveThread}>
                          <div><SVG src={isThreadArchived ? "archived-outlined" : "archived"}/></div>
                          {isThreadArchived ? t('communication_thread_unarchive') : t('communication_thread_archive')}
                        </Button>
                        <Button className={classes.customizeButton} disabled={!isThreadAuthor} onClick={handleDeleteThread}>
                          <div><SVG src="trash"/></div>
                          {t('communication_thread_delete')}
                        </Button>
                      </>
                    ) : null
                  }
                  {
                    !isThreadAuthor ? (
                      <Button className={classes.customizeButton} disabled={isThreadAuthor} onClick={handleLeaveThread}>
                        <div><SVG src="leave"/></div>
                        {t('communication_thread_leave')}
                      </Button>
                    ) : null
                  }
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.accordion} defaultExpanded={true} data-cy={isCypress() ? 'communicationMediaAttachments' : null}>
                <AccordionSummary className={classes.accordionSummary} expandIcon={<SVG src="chevron-down"/>}>
                  <span>{t('communication_media_and_attachments')}</span>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <Button className={classes.customizeButton} onClick={handleGoMedia}>
                    <div><SVG src="image"/></div>
                    {t('communication_media')}
                  </Button>
                  <Button className={classes.customizeButton} onClick={handleGoAttachments}>
                    <div><SVG src="file"/></div>
                    {t('communication_attachment')}
                  </Button>
                </AccordionDetails>
              </Accordion>
              {
                allUsers.length > 0 ? (
                  <Accordion className={classes.accordion} defaultExpanded={true} data-cy={isCypress() ? 'communicationRecipients' : null}>
                    <AccordionSummary className={classes.accordionSummary} expandIcon={<SVG src="chevron-down"/>}>
                      <span>{t('communication_users')} ({allUsers.length})</span>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      {
                        allUsers.map((item: any, key: any) => {
                          const groupUserData = getUserData(item);
                          const dropdownMenuItems = [
                            groupUserData.userID !== userData.userObject.userID ? {
                              title: t('communication_message'),
                              icon: (<SVG src="communication"/>),
                              onClick: () => handleNewMessage(groupUserData.userID, schoolID),
                              dataCy: '',
                            } : null,
                            (isThreadAuthor && groupUserData.userID !== threadData.authorID) ? {
                              title: t('communication_remove_user'),
                              icon: (<SVG src="trash"/>),
                              onClick: () => handleRemoveRecipient(groupUserData.userID),
                              disabled: isThreadArchived,
                              dataCy: '',
                            } : null,
                          ].filter((item: any) => item !== null);
                          return groupUserData ? (
                            <div className={classes.userButton} key={`k_${key}`}>
                              <AuthenticatedImage className={classes.avatar} thumbLink={groupUserData.photo.thumbLink}/>
                              <span>
                                <span>{groupUserData.displayName}</span>
                                <p>{groupUserData.userID === threadData.authorID ? t('communication_users_author') : t('communication_users_user')}</p>
                              </span>
                              {
                                users.length > 1 ? (
                                  <DropdownWithMenu className={classes.dropdownMenu} items={dropdownMenuItems} classNamePopover={classes.dropdownMenuPopover} classNameMenuButton={classes.dropdownMenuButton} classNameMoreButton={classes.moreButton} moreButtonSize='small'/>
                                ) : null
                              }
                            </div>
                          ) : null;
                        })
                      }
                      {
                        isThreadAuthor ? (
                          <>
                            {
                              state.isUsersSelectOpen ? (
                                <UsersSelect
                                  isSelectAll={true}
                                  isMultipleSelect={true}
                                  isAllowSearch={true}
                                  isInModal={true}
                                  isModalOpen={true}
                                  isGroupByRoles={true}
                                  groupRoles={roles}
                                  modalTitle='recipients'
                                  modalOnClose={() => setState("isUsersSelectOpen", false)}
                                  modalOnSave={(value: any) => handleRecipients(value)}
                                  modalAllowClose={true}
                                  modalAllowClear={true}
                                  modalAllowNoUser={true}
                                  mode="select"
                                  defaultSchools={[{schoolID: schoolID}]}
                                  defaultUsers={availableUsers}
                                  maxUsersSelected={maxUsers - allUsers.length}
                                  customLimitMessage={t('limit_exceeded_users_thread_add')}
                                />
                              ) : null
                            }
                            <Button className={classes.customizeButton} disabled={!isThreadAuthor || isThreadArchived || allUsers.length >= maxUsers || !userAccessSchools.communication.includes(schoolID)} onClick={handleAddRecipients}>
                              <div><SVG src="plus"/></div>
                              {t('communication_add_user')}
                            </Button>
                          </>
                        ) : null
                      }
                    </AccordionDetails>
                  </Accordion>
                ) : null
              }
            </div>
          </>
        ) : state.section === "media" ? (
          <>
            <div className={classes.header}>
              <IconButton tooltip={t('back')} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={handleGoDefault}>
                <SVG src="arrow-left"/>
              </IconButton>
              <span>{t('communication_media')}</span>
            </div>
            <div className={classes.gallery}>
              <CommunicationThreadGallery threadID={threadID} type="photo"/>
            </div>
          </>
        ) : state.section === "attachments" ? (
          <>
            <div className={classes.header}>
              <IconButton tooltip={t('back')} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={handleGoDefault}>
                <SVG src="arrow-left"/>
              </IconButton>
              <span>{t('communication_attachment')}</span>
            </div>
            <div className={classes.gallery}>
              <CommunicationThreadGallery threadID={threadID} type="attachment"/>
            </div>
          </>
        ) : null
      }
    </div>
  );
};

export default Sidebar;