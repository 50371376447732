import { createTheme as createMuiTheme } from '@mui/material/styles';

interface Theme {
  [key: string]: any;
};

const appThemes: any = {
  colors: {
    light: {
      grey: {
        10: '#F1F1F1',
        50: '#F7F7F7',
        75: '#F8F8F8',
        100: '#F9F9F9',
        125: '#F9F9FF',
        150: '#FAFAFA',
        200: '#F1F3FF',
        225: '#E1E1E1',
        250: '#E1F1FF',
        300: '#E2E4F5',
        325: '#E8E8E8',
        350: '#EFF0F7',
        400: '#C2C7DE',
        500: '#A4A7C1',
        540: '#828894',
        550: '#8B8B8B',
        560: '#949494',
        570: '#989898',
        600: '#9396B2',
        650: '#9C9EB9',
        700: '#6E7191',
        800: '#3A3E53',
      },
      primaryBlue: {
        50: '#EAF6FF',
        100: '#CFEAFF',
        200: '#97CFFC',
        300: '#64B7FB',
        400: '#34A0FA',
        500: '#0085FF',
        600: '#0065C9',
        700: '#01509B',
        800: '#003A72',
        900: '#00274A',
      },
      systemGreen: {
        50: '#ECFDF5',
        100: '#D1FAE5',
        200: '#A7F3D0',
        300: '#6EE7B7',
        400: '#34D399',
        500: '#10B981',
      },
      systemRed: {
        50: '#FEF5F6',
        75: '#FFF1F3',
        100: '#FAE0E3',
        200: '#F6BFC8',
        300: '#F1A0AC',
        400: '#EE7F91',
        500: '#EB4D63',
      },
      systemOrange: {
        50: '#FEF9F2',
        100: '#FCEED6',
        200: '#FADEAA',
        300: '#F8CC81',
        400: '#F5BC5A',
        500: '#F59E0B',
      },
      yellow: {
        50: '#FFFCF1',
        100: '#FFF7D5',
        200: '#FDECA8',
        300: '#FBE180',
        400: '#FBD858',
        500: '#FCC603',
      },
      pink: {
        50: '#FFF7FA',
        100: '#FDE5F1',
        200: '#FCCCE0',
        300: '#FAB1D3',
        400: '#F898C3',
        500: '#F970AD',
      },
      purple: {
        50: '#F7F6FE',
        100: '#E7E3FC',
        200: '#D9D1F8',
        300: '#BAACF3',
        400: '#A191F0',
        500: '#7A67EC',
      },
      royalPurple: {
        50: '#FBF5FE',
        100: '#F2E1F8',
        200: '#E3C2F1',
        300: '#D7A5E8',
        400: '#CD91E5',
        500: '#B559DA',
      },
      brightGreen: {
        50: '#F7FDF6',
        100: '#E7F7E2',
        200: '#CDEDC5',
        300: '#B5E4A9',
        400: '#9CDC8D',
        500: '#6FCB59',
      },
      brightRed: {
        50: '#FFF5F2',
        100: '#FDE1D9',
        200: '#FCC2B3',
        300: '#FAA38C',
        400: '#F88565',
        500: '#FB5324',
      },
      hoverBlack: {
        4: 'rgba(0, 0, 0, 0.04)',
      },
      white: '#FFFFFF',
      black: '#000000',
      alwaysWhite: '#FFFFFF',
      alwaysBlack: '#000000',
      chip: '#F3F4FF',
      gradient: 'linear-gradient(to bottom, #DDE1F2, #ECEDF7)',
    },
    dark: {
      grey: {
        10: '#F1F1F1',
        50: '#F7F7F7',
        75: '#F8F8F8',
        100: '#F9F9F9',
        125: '#F9F9FF',
        150: '#FAFAFA',
        200: '#1A1A1A',
        225: '#E1E1E1',
        250: '#E1F1FF',
        300: '#E2E4F5',
        325: '#616161',
        350: '#EFF0F7',
        400: '#C2C7DE',
        500: '#A4A7C1',
        540: '#828894',
        550: '#8B8B8B',
        560: '#949494',
        570: '#989898',
        600: '#9396B2',
        650: '#A8AABC',
        700: '#B6B8C8',
        800: '#A8ACBD',
      },
      primaryBlue: {
        50: '#EAF6FF',
        100: '#CFEAFF',
        200: '#97CFFC',
        300: '#64B7FB',
        400: '#34A0FA',
        500: '#0085FF',
        600: '#0065C9',
        700: '#01509B',
        800: '#003A72',
        900: '#00274A',
      },
      systemGreen: {
        50: '#ECFDF5',
        100: '#D1FAE5',
        200: '#A7F3D0',
        300: '#6EE7B7',
        400: '#34D399',
        500: '#10B981',
      },
      systemRed: {
        50: '#FEF5F6',
        75: '#FFF1F3',
        100: '#FAE0E3',
        200: '#F6BFC8',
        300: '#F1A0AC',
        400: '#EE7F91',
        500: '#EB4D63',
      },
      systemOrange: {
        50: '#FEF9F2',
        100: '#FCEED6',
        200: '#FADEAA',
        300: '#F8CC81',
        400: '#F5BC5A',
        500: '#F59E0B',
      },
      yellow: {
        50: '#FFFCF1',
        100: '#FFF7D5',
        200: '#FDECA8',
        300: '#FBE180',
        400: '#FBD858',
        500: '#FCC603',
      },
      pink: {
        50: '#FFF7FA',
        100: '#FDE5F1',
        200: '#FCCCE0',
        300: '#FAB1D3',
        400: '#F898C3',
        500: '#F970AD',
      },
      purple: {
        50: '#F7F6FE',
        100: '#E7E3FC',
        200: '#D9D1F8',
        300: '#BAACF3',
        400: '#A191F0',
        500: '#7A67EC',
      },
      royalPurple: {
        50: '#FBF5FE',
        100: '#F2E1F8',
        200: '#E3C2F1',
        300: '#D7A5E8',
        400: '#CD91E5',
        500: '#B559DA',
      },
      brightGreen: {
        50: '#F7FDF6',
        100: '#E7F7E2',
        200: '#CDEDC5',
        300: '#B5E4A9',
        400: '#9CDC8D',
        500: '#6FCB59',
      },
      brightRed: {
        50: '#FFF5F2',
        100: '#FDE1D9',
        200: '#FCC2B3',
        300: '#FAA38C',
        400: '#F88565',
        500: '#FB5324',
      },
      hoverBlack: {
        4: 'rgba(255, 255, 255, 0.04)',
      },
      white: '#1F1F1F',
      black: '#FFFFFF',
      alwaysWhite: '#FFFFFF',
      alwaysBlack: '#000000',
      chip: '#F3F4FF',
      gradient: 'linear-gradient(to bottom, #000000, #000000)',
    },
  },
  shadows: {
    light: [
      'none',
      '0 4px 12px rgba(0, 0, 0, 0.1)',
      '0px 3px 20px rgba(0, 0, 0, 0.08)',
      '0px 0px 0px 1px rgba(140, 140, 140, 0.2), 0px 4px 4px rgba(0, 0, 0, 0.3)',
      '0px 3px 20px rgba(252, 198, 3, 0.2)',
      '0px 3px 10px rgba(0, 0, 0, 0.08)',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
    dark: [
      'none',
      '0 4px 12px rgba(255, 255, 255, 0.1)',
      '0px 3px 20px rgba(255, 255, 255, 0.08)',
      '0px 0px 0px 1px rgba(140, 140, 140, 0.2), 0px 4px 4px rgba(255, 255, 255, 0.3)',
      '0px 3px 20px rgba(252, 198, 3, 0.2)',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
  },
};

const getTheme = (scheme: any) => {
  const themeOptions: Theme = {
    typography: {
      fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        co: 800,
        md: 960,
        bg: 1150,
        lg: 1500,
        xl: 1920,
        xxl: 2240,
        xxxl: 2560,
        xxxxl: 3840,
      },
      dashboard: {
        xs: 0,
        sm: 300,
        co: 500,
        md: 660,
        bg: 850,
        lg: 1200,
        xl: 1620,
        xxl: 1940,
        xxxl: 2260,
        xxxxl: 3540,
      },
    },
    colors: scheme ? (Object.keys(appThemes.colors[scheme]).length === 0 ? appThemes.colors.light : appThemes.colors[scheme]) : appThemes.colors.light,
    colorsNames: Object.keys(scheme ? (Object.keys(appThemes.colors[scheme]).length === 0 ? appThemes.colors.light : appThemes.colors[scheme]) : appThemes.colors.light).filter((item: any) => typeof (scheme ? (Object.keys(appThemes.colors[scheme]).length === 0 ? appThemes.colors.light : appThemes.colors[scheme]) : appThemes.colors.light)[item] === "object" && [50, 100, 200, 300, 400, 500].every((key: any) => (scheme ? (Object.keys(appThemes.colors[scheme]).length === 0 ? appThemes.colors.light : appThemes.colors[scheme]) : appThemes.colors.light)[item].hasOwnProperty(key))),
    shadows: scheme ? (Object.keys(appThemes.shadows[scheme]).length === 0 ? appThemes.shadows.light : appThemes.shadows[scheme]) : appThemes.shadows.light,
    zIndex: {
      negative: -1,
      tabsWrapper: 10,
      tableLoading: 1000,
      calendarLoading: 1000,
      calendarHeader: 1001,
      addButton: 1002,
      backButton: 1002,
      container: 1200,
      drawer: 1201,
      bottomBar: 1202,
      bottomBarMenu: 1201,
      filters: 1202,
      topBar: 1203,
      logo: 1204,
      profileMenu: 1204,
      toolbar: 1205,
      profileMenuItem: 1205,
      searchBar: 1206,
      banner: 1300,
      modal: 1300,
      popperWrapper: 1301,
      infoBar: 1401,
      contextMenu: 9997,
      popper: 9998,
      tooltip: 9999,
      cookiesBanner: 9999,
      cypressTag: 9999,
      notification: 9999,
      screen: 9999,
      dragAndDrop: 999999,
      inactiveZone: 999999,
      overlay: 999999,
    },
  };
  return createMuiTheme(themeOptions);
};

const theme: Theme = getTheme("light");

export { getTheme };

export default theme;