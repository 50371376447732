import Dayview from './components/Dayview';
import moment from 'src/utils/moment';
import React, { useCallback, useRef } from 'react';
import Sidebar from 'src/components/Layouts/Sidebar';
import TabsMenu from '../../../components/Menus/TabsMenu';
import Weekview from './components/Weekview';
import { arrayToObject, getFiltersKeysArray, getQueryString } from 'src/utils/useFunctions';
import { createUseStyles } from 'react-jss';
import { getUserSetting, saveUserSettings } from 'src/utils/useUser';
import { resetClassbook, setClassbookDate, setClassbookFilterClassID, setClassbookFilterSchoolID } from 'src/store/actions/classbook.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useParams } from 'react-router';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from '../../../utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  classbookPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '0px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '0px',
    },
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    '& > div': {
      width: '80%',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  wrapperColumn: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  classbookWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { tab } = useParams();

  const [state, setState] = useStates({
    viewMode: "weekview",
    isReady: false,
  });

  const queryString = getQueryString(location);

  const classbookData = useAppSelector((state: any) => state.classbook);
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);

  const dailyClasses = dataData.classes.filter((item: any) => item.classbookType === "daily");

  const isSaving = useRef(false);

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const getClassData = useCallback((classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  }, [dataData.classes]);

  const getAvailableSchools = useCallback(() => {
    return dataData.schools.map((theSchool: any) => {
      const filteredClasses = dataData.classes.filter((item: any) => item.schoolID === theSchool.schoolID);
      if(filteredClasses.length !== 0) {
        const childrenInClasses = filteredClasses.map((item: any) => {
          return item.childrenID;
        }).flat();
        if(childrenInClasses.length !== 0) {
          return theSchool;
        } else {
          return null;
        }
      } else {
        return null;
      }
    }).filter((item: any) => item !== null);
  }, [dataData.schools, dataData.classes]);

  const getAvailableClasses = useCallback(() => {
    return dataData.classes.map((theClass: any) => {
      const childrenInClasses = theClass.childrenID;
      if(childrenInClasses.length !== 0) {
        return theClass;
      } else {
        return null;
      }
    }).filter((item: any) => item !== null);
  }, [dataData.classes]);

  const getDefaultSchool = getAvailableSchools().map((item: any) => { return item.schoolID; })[0];

  const getDefaultClass = useCallback((schoolID?: any) => {
    return getAvailableClasses().filter((item: any) => item.schoolID === (schoolID ? schoolID : getDefaultSchool) && !item.isArchived).map((item: any) => { return item.classID; })[0];
  }, [getAvailableClasses, getDefaultSchool]);

  const filterSchoolID = classbookData.filterSchoolID;
  const filterClassID = classbookData.filterClassID;
  const curDate = classbookData.date;

  const tabsItems: any = useMemo(() => [
    {
      name: 'week_view',
      value: 'weekview',
      isEnabled: true,
    },
    {
      name: 'day_view',
      value: 'dayview',
      disabled: filterClassID ? getClassData(filterClassID[0]).classbookType === "weekly" : true,
      isEnabled: true,
    }
  ], [filterClassID, getClassData]);

  const setViewMode = useCallback((viewMode: any) => {
    setState("viewMode", viewMode);
  }, [setState]);

  const handleSetViewMode = useCallback((value: any) => {
    setViewMode(value);
    window.history.replaceState({}, '', `/classbook/${value}${queryString}`);
  }, [setViewMode, queryString]);

  const isFirstTime: any = useRef(true);

  const filters = {
    schoolID: filterSchoolID,
    classID: filterClassID,
    date: curDate,
  };

  const saveFilters = useCallback(async (filterParam: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      if(getUserSetting(userData.userSettings, "addons", ["classbook", "classbook_filters_save"])) {
        const filtersParams = getFiltersKeysArray(filterParam, {});
        const result = await saveUserSettings(dispatch, userData, "filters", ["classbook"], filtersParams);
        if(result) {
          isSaving.current = false;
        } else {
          isSaving.current = false;
        }
      }
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if(tab) {
      if(tabsItems.filter((item: any) => item.value === tab).length === 0) {
        setViewMode(tabsItems[0].value);
      } else {
        if(tab === "dayview" && (filterClassID ? getClassData(filterClassID[0]).classbookType : "weekly") === "weekly") {
          handleSetViewMode("weekview");
        } else {
          window.history.replaceState({},'',"/classbook/" + tab);
        }
      }
    } else {
      handleSetViewMode("weekview");
    }
    return () => {
      dispatch(resetClassbook());
    };
  }, [dispatch, tab, setViewMode, tabsItems, filterClassID, getClassData, handleSetViewMode], []);

  useEffect(() => {
    if(getUserSetting(userData.userSettings, "addons", ["classbook", "classbook_filters_save"])) {
      const customFilters = getUserSetting(userData.userSettings, "filters", ["classbook"]);
      if(Array.isArray(customFilters)) {
        const customFiltersObject = arrayToObject(customFilters);
        if(customFiltersObject.date) {
          dispatch(setClassbookDate(moment(customFiltersObject.curDate)));
        } else {
          dispatch(setClassbookDate(moment()));
        }
        if(customFiltersObject.schoolID && dataData.schoolsID.includes(customFiltersObject.schoolID[0])) {
          const schoolID = customFiltersObject.schoolID;
          dispatch(setClassbookFilterSchoolID(schoolID));
        } else {
          const schoolID = [getDefaultSchool];
          dispatch(setClassbookFilterSchoolID(schoolID));
        }
        if(customFiltersObject.classID && dataData.classesID.includes(customFiltersObject.classID[0])) {
          const classID = customFiltersObject.classID;
          dispatch(setClassbookFilterClassID(classID));
        } else {
          const schoolID = customFiltersObject.schoolID ? customFiltersObject.schoolID : [getDefaultSchool];
          const classID = [getDefaultClass(schoolID[0])];
          dispatch(setClassbookFilterClassID(classID));
        }
      } else {
        const schoolID = [getDefaultSchool];
        const classID = [getDefaultClass()];
        dispatch(setClassbookDate(moment()));
        dispatch(setClassbookFilterSchoolID(schoolID));
        dispatch(setClassbookFilterClassID(classID));
      }
    } else {
      const schoolID = [getDefaultSchool];
      const classID = [getDefaultClass()];
      dispatch(setClassbookDate(moment()));
      dispatch(setClassbookFilterSchoolID(schoolID));
      dispatch(setClassbookFilterClassID(classID));
    }
    setState("isReady", true);
  }, [userData.userSettings, dataData.schoolsID, dataData.classesID, dispatch, getClassData, getSchoolData, getDefaultClass, getDefaultSchool, setState], []);

  useEffect(() => {
    if(isFirstTime.current === false) {
      const filterParams = {
        curDate: curDate,
        schoolID: filterSchoolID.length !== 0 ? filterSchoolID : [],
        classID: filterClassID.length !== 0 ? filterClassID : [],
      };
      saveFilters(filterParams);
    } else {
      isFirstTime.current = false;
    }
  }, [filterClassID, filterSchoolID, curDate, saveFilters], [filterClassID, filterSchoolID, curDate]);

  return (
    <div className={classes.classbookPage}>
      {
        state.isReady ? (
          <>
            {
              dailyClasses.length > 0 ? (
                <div className={classes.tabsWrapper}>
                  <TabsMenu items={tabsItems} selected={state.viewMode} onSelect={handleSetViewMode}/>
                  <Sidebar/>
                </div>
              ) : null
            }
            <div className={classes.wrapper}>
              <div className={classes.wrapperColumn}>
                <div className={classes.classbookWrapper}>
                {
                  state.viewMode === "weekview" ? (
                    <Weekview filters={filters}/>
                  ) : (
                    <Dayview filters={filters}/>
                  )
                } 
                </div>
              </div>
              <Sidebar/>
            </div>
          </>
        ) : null
      }
    </div>
  );
};

export default PageTemplate;