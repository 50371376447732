import PressReport from './Components/PressReport';
import React from 'react';
import tabs from 'src/constants/tabs';
import TabsMenu from 'src/components/Menus/TabsMenu';
import { createUseStyles } from 'react-jss';
import { getUserRole } from '../../../utils/useUser';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useParams } from 'react-router';
import { useStates } from 'src/utils/useState';
import { getQueryString } from 'src/utils/useFunctions';

const useStyles = createUseStyles((theme: any) => ({
  pressReportPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '24px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '0',
      width: '100%',
      maxWidth: '100%',
    },
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    marginBottom: '16px',
    '& > div': {
      width: '100%',
    },
    '&:empty': {
      display: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    height: 'calc(100% - 16px)',
    maxHeight: 'calc(100% - 16px)',
    [theme.breakpoints.down('md')]: {
      height: 'unset !important',
      maxHeight: 'unset !important',
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const { tab } = useParams();
  const location = useLocation();
  const userData = useAppSelector((state: any) => state.user);

  const queryString = getQueryString(location);
  
  const tabsItems = tabs.pressReport.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));

  const [state, setState] = useStates({
    viewMode: "children",
  });

  const classes = useStyles();

  const setViewMode = (value: any) => {
    setState("viewMode", value);
    window.history.replaceState({}, '', `/press-report/${value}${queryString}`);
  };

  useEffect(() => {
    if(tab) {
      if(tabsItems.filter((item: any) => item.value === tab).length === 0) {
        const value = tabsItems[0].value;
        setState("viewMode", value);
        window.history.replaceState({},'',`/press-report/${value}${queryString}`);
      } else {
        setState("viewMode", tab);
        window.history.replaceState({},'',`/press-report/${tab}${queryString}`);
      }
    } else {
      setState("viewMode", tabsItems[0].value);
      window.history.replaceState({}, '', `/press-report/${tabsItems[0].value}${queryString}`);
    }
  }, [setState, tabsItems, tab, queryString], [tab]);

  return (
    <div className={classes.pressReportPage}>
      <div className={classes.tabsWrapper}>
        <TabsMenu items={tabsItems} selected={state.viewMode} onSelect={setViewMode}/>
      </div>
      <div className={classes.wrapper}>
        {
          state.viewMode === "children" ? (
            <PressReport/>
          ) : null
        }
      </div>
    </div>
  );
};

export default PageTemplate;