import ChildrenSelect from '../../Selects/ChildrenSelect';
import ClassesSelect from 'src/components/Selects/ClassesSelect';
import dateFormat from '../../../utils/dateFormat';
import EmployeesSelect from 'src/components/Selects/EmployeesSelect';
import FiltersActivityTypesModal from '../../Modals/FiltersActivityTypesModal';
import FiltersDatesModal from '../../Modals/FiltersDatesModal';
import FiltersGalleryTypesModal from '../../Modals/FiltersGalleryTypesModal';
import FiltersPollsModal from '../../Modals/FiltersPollsModal';
import FiltersPostTypesModal from '../../Modals/FiltersPostTypesModal';
import IconButton from 'src/components/Buttons/IconButton';
import moment from '../../../utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import React, { useCallback } from 'react';
import SchoolsSelect from 'src/components/Selects/SchoolsSelect';
import ScrollMenu from '../../Menus/ScrollMenu';
import Select from '../../Forms/Select';
import SVG from '../../Images/SvgRenderer';
import useBreakpoint from 'src/utils/useBreakpoint';
import UsersSelect from 'src/components/Selects/UsersSelect';
import { createUseStyles } from 'react-jss';
import { getActiveFilters, getQueryParams, getSideContentSize, removeDuplicatesJSON } from 'src/utils/useFunctions';
import { getUserSetting } from 'src/utils/useUser';
import { InView } from 'react-intersection-observer';
import { resetAllFiltersSettings, resetFilterParams, setFilterParams, setIsFilterParamsLoaded, setIsFilterSettingsLoaded, setIsFilterSetupLoaded } from '../../../store/actions/filters.actions';
import { setIsFiltersVisible } from '../../../store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation } from 'react-router';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  isVisible: any;
  isSideContentVisible: any;
  isEmbed: any;
};

const useStyles = createUseStyles((theme: any) => ({
  filtersWrapper: {
    position: (props: Props) => {
      if(props.isEmbed) return 'sticky';
      else return 'absolute';
    },
    top: (props: Props) => {
      if(props.isEmbed) return '0px';
      else return '100%';
    },
    left: (props: Props) => {
      if(props.isEmbed) return '';
      else if(props.isSideContentVisible) return 'calc(50% + 60px)';
      else return '50%';
    },
    transform: (props: Props) => {
      if(props.isEmbed) return '';
      else if(props.isSideContentVisible) return 'translateX(calc(-50% + 60px))';
      else return 'translateX(-50%)';
    },
    height: 'fit-content',
    width: (props: Props) => {
      if(props.isEmbed) return '80%';
      else return '60%';
    },
    backgroundColor: theme.colors.white,
    transition: 'height 0.5s, padding 0.5s',
    borderRadius: "0 0 24px 24px",
    zIndex: (props: Props) => {
      if(props.isEmbed) return theme.zIndex.filters;
      else return '';
    },
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
      borderRadius: "0px",
    },
    '&::before': {
      content: `''`,
      position: 'absolute',
      left: '0',
      bottom: '100%',
      width: '100%',
      backgroundColor: theme.colors.white,
      height: '15px',
      zIndex: '1',
    },
  },
  filters: {
    height: (props: Props) => {
      if(props.isVisible) return '60px';
      else return '0px';
    },
    width: 'calc(100% - 48px)',
    padding: '0px 24px',
    backgroundColor: theme.colors.white,
    transition: 'height 0.5s, padding 0.5s',
    borderRadius: "0 0 24px 24px",
    overflow: 'hidden',
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '0px',
      borderRadius: "0px",
    },
  },
  filtersInner: {
    display: "flex",
    gap: '10px',
    justifyContent: "flex-start",
    width: 'calc(100% - 16px)', 
    padding: '9px 8px',
    position: 'relative',
  },
  button: {
    borderRadius: '51px !important',
    width: 'fit-content',
    whiteSpace: 'nowrap',
    minWidth: 'unset !important',
    position: 'relative',
    '& > span': {
      height: '100%',
      '& > svg': {
        width: '24px',
        height: '24px',
      },
    },
  },
  iconButton: {
    borderRadius: '100%',
    width: '42px',
    height: '42px',
    backgroundColor: theme.colors.systemRed[500],
    '&:hover': {
      backgroundColor: theme.colors.systemRed[400],
    },
    '& > svg': {
      width: '24px',
      height: '24px',
      color: theme.colors.alwaysWhite,
    },
  },
  chipIcon: {
    position: 'absolute',
    top: '-8px',
    right: '-12px',
    width: '22px',
    height: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '9px !important',
    backgroundColor: theme.colors.primaryBlue[500],
    color: theme.colors.white,
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    borderRadius: '100%',
  },
}));

const Filters: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const dataData = useAppSelector((state: any) => state.data);
  const filtersData = useAppSelector((state: any) => state.filters);
  const languageData = useAppSelector((state: any) => state.language);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);

  const queryParams = getQueryParams(location);
  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;
  const enableFilters = queryParams.get("enableFilters");
  const isEnableFilters = enableFilters === "true" ? true : false;
  const breakpoint: any = useBreakpoint();
  const isVisible = (isEmbed && isEnableFilters && filtersData.isFilterSettingsLoaded) ? true : layoutData.isFiltersVisible;
  const isSideContentVisible = getSideContentSize(breakpoint) !== 0 && layoutData.sideContent !== null;
  
  const classes = useStyles({
    isVisible: isVisible,
    isSideContentVisible: isSideContentVisible,
    isEmbed: isEmbed,
  });

  const filtersActiveCount = getActiveFilters(filtersData.filterParams);
  const useQuickClearButton = getUserSetting(userData.userSettings, "addons", ["app", "app_filter_fast_button"]);

  const listClasses = dataData.classes.filter((item: any) => item.childrenID.length !== 0);
  const listSchools = dataData.schools.filter((item: any) => listClasses.filter((theClass: any) => theClass.schoolID === item.schoolID).length !== 0);

  const [state, setState] = useStates({
    isClearFiltersVisible: false,
    openPostTypesModal: false,
    openGalleryTypesModal: false,
    openActivityTypesModal: false,
    openChildrenModal: false,
    openEmployeesModal: false,
    openDatesModal: false,
    openAuthorsModal: false,
    openPollsModal: false,
    openClassesModal: false,
    openSchoolsModal: false,
  });

  const handleModalState = (type: any, value: any) => {
    setState(`open${type}Modal`, value);
  };
  
  const clearSelection = useCallback(() => {
    dispatch(resetFilterParams()); 
  }, [dispatch]);

  const clearFilters = useCallback(() => {
    clearSelection();
  }, [clearSelection]);
  
  useEffect(() => {
    return () => {
      dispatch(setIsFiltersVisible(false));
      dispatch(resetFilterParams());
      dispatch(resetAllFiltersSettings());
      dispatch(setIsFilterParamsLoaded(false));
      dispatch(setIsFilterSettingsLoaded(false));
      dispatch(setIsFilterSetupLoaded(false));
    };
  }, [dispatch], []);

  useEffect(() => {
    dispatch(resetFilterParams());
    dispatch(setIsFilterSetupLoaded(true));
    if(filtersData.isFilterParamsLoaded && filtersData.isFilterSettingsLoaded && filtersData.isFilterSetupLoaded) {
      dispatch(setIsFiltersVisible(false));
      dispatch(setIsFilterParamsLoaded(false));
      dispatch(setIsFilterSettingsLoaded(false));
    }
  }, [location, dispatch, filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded, filtersData.isFilterSetupLoaded], [location])

  const handleSavePostID = () => {
    dispatch(setFilterParams({postID: null}));
  };

  const handleSavePaymentID = () => {
    dispatch(setFilterParams({paymentID: null}));
  };
  
  const handleSavePostTypesModal = (value: any) => {
    if(value === null) {
      dispatch(setFilterParams({postTypeID: null}));
    } else {
      if(filtersData.filterParams.pollVoted) {
        if(value === 1) {
          dispatch(setFilterParams({postTypeID: value, pollVoted: null}));
        }
      } else {
        dispatch(setFilterParams({postTypeID: value}));
      }  
    }
  };
  
  const handleSaveGalleryTypesModal = (value: any) => { 
    if(value.length === 0) {
      dispatch(setFilterParams({galleryTypeID: []}));
    } else {
      dispatch(setFilterParams({galleryTypeID: value}));
    }
  };
  
  const handleSaveActivityModal = (value: any) => {
    if(value.length === 0) {
      dispatch(setFilterParams({activityTypeID: []}));
    } else {
      dispatch(setFilterParams({activityTypeID: value}));
    }
  };
  
  const handleSaveChildrenModal = (value: any) => {
    value = value.map((item: any) => {
      return item.childID;
    });
    if(value.length === 0) {
      dispatch(setFilterParams({childID: []}));
    } else {
      dispatch(setFilterParams({childID: value}));
    }
  };

  const handleSaveEmployeesModal = (value: any) => {
    value = value.map((item: any) => {
      return item.employeeID;
    });
    if(value.length === 0) {
      dispatch(setFilterParams({employeeID: []}));
    } else {
      dispatch(setFilterParams({employeeID: value}));
    }
  };
  
  const handleSaveDatesModal = (from: any, to: any) => {
    const newFrom = from === null ? null : (moment(from).format("YYYY-MM-DD")).toString();
    const newTo = to === null ? null : (moment(to).format("YYYY-MM-DD")).toString();
    dispatch(setFilterParams({dateFrom: newFrom, dateTo: newTo}));
  };
  
  const handleSaveAuthorsModal = (value: any) => {
    value = value.map((item: any) => { return item.userID });
    if(value.length === 0) {
      dispatch(setFilterParams({authorID: []}));
    } else {
      dispatch(setFilterParams({authorID: value}));
    }
  };
  
  const handleSavePollsModal = (value: any) => {
    if(value === null) {
      dispatch(setFilterParams({pollVoted: null}));
    } else {
      if(filtersData.filterParams.postTypeID) {
        if(filtersData.filterParams.postTypeID === 1) {
          dispatch(setFilterParams({pollVoted: value, postTypeID: 2}));
        }
      } else {
        dispatch(setFilterParams({pollVoted: value.toString()}));
      } 
    }
  };
  
  const handleSaveClassesModal = (value: any) => {
    value = value.map((item: any) => { return item.classID });
    if(value.length === 0) {
      dispatch(setFilterParams({classID: []}));
    } else {
      dispatch(setFilterParams({classID: value}));
    }
  };
  
  const handleSaveSchoolsModal = (value: any) => {
    value = value.map((item: any) => { return item.schoolID });
    if(value.length === 0) {
      dispatch(setFilterParams({schoolID: []}));
    } else {
      dispatch(setFilterParams({schoolID: value}));
    }
  };

  const handleSaveTimelineFavorite = () => {
    if(filtersData.filterParams.timelineFavorite) {
      dispatch(setFilterParams({timelineFavorite: null}));
    } else {
      dispatch(setFilterParams({timelineFavorite: true}));
    }
  };
  
  const handleSaveGalleryFavorite = () => {
    if(filtersData.filterParams.galleryFavorite) {
      dispatch(setFilterParams({galleryFavorite: null}));
    } else {
      if(filtersData.filterParams.galleryHide) {
        dispatch(setFilterParams({galleryFavorite: true, galleryHide: null}));
      } else {
        dispatch(setFilterParams({galleryFavorite: true}));
      }
    }
  };
  
  const handleSaveGalleryHide = () => {
    if(filtersData.filterParams.galleryHide) {
      dispatch(setFilterParams({galleryHide: null}));
    } else {
      if(filtersData.filterParams.galleryFavorite) {
        dispatch(setFilterParams({galleryHide: true, galleryFavorite: null}));
      } else {
        dispatch(setFilterParams({galleryHide: true}));
      }
    }
  };  

  const handleSavePaymentMethod = (value: any) => {
    if(value === null || value === 'null') {
      dispatch(setFilterParams({paymentMethod: null}));
    } else {
      dispatch(setFilterParams({paymentMethod: value.paymentMethodID}));
    }
  };
  
  const handleSavePaymentStatus = (value: any) => {
    if(value === null || value === 'null') {
      dispatch(setFilterParams({paymentStatus: null}));
    } else {
      dispatch(setFilterParams({paymentStatus: value.paymentStatusID}));
    }
  };  

  const handleSavePaymentType = (value: any) => {
    if(value === null || value === 'null') {
      dispatch(setFilterParams({paymentType: null}));
    } else {
      dispatch(setFilterParams({paymentType: value.paymentTypeID}));
    }
  };  

  const handleSaveCurrency = (value: any) => {
    if(value === null || value === 'null') {
      dispatch(setFilterParams({currencyID: null}));
    } else {
      dispatch(setFilterParams({currencyID: value.currencyID}));
    }
  };

  const onScrollView = (inView: any) => {
    setState("isClearFiltersVisible", inView);
  };
 
  return (!isEmbed || (isEmbed && isEnableFilters)) ? (
    <div className={classes.filtersWrapper}>
      <ScrollMenu classNameWrapper={classes.filters} className={classes.filtersInner} layout="horizontal" nativeMobileScroll={false} prependContent={(!state.isClearFiltersVisible && filtersActiveCount !== 0 && useQuickClearButton) ? (<IconButton className={classes.iconButton} onClick={clearFilters}><SVG src="filter-close"/></IconButton>) : null}>
        <InView onChange={(inView) => onScrollView(inView)}>
          <NormalButton className={classes.button} buttonType={filtersActiveCount === 0 ? "filter" : "clear"} startIcon={<SVG src="filter"/>} onClick={clearFilters} disabled={filtersActiveCount === 0 ? true : false} dataCy="filtersMenuClearButton">
            {filtersActiveCount === 0 ? t("filters") : t("clear")}
          </NormalButton>
        </InView>
        {
          (filtersData.filtersSettings.isAllowedPostID && filtersData.filterParams.postID !== null) ? (
            <NormalButton className={classes.button} buttonType={filtersData.filterParams.postID !== null ? 'brand' : 'chip'} startIcon={<SVG src="posts-notes"/>} onClick={handleSavePostID} dataCy="filtersMenuPostIDButton">
              {t("selected_posts")}
            </NormalButton>
          ) : null
        }
        {
          (filtersData.filtersSettings.isAllowedPaymentID && filtersData.filterParams.paymentID !== null) ? (
            <NormalButton className={classes.button} buttonType={filtersData.filterParams.paymentID !== null ? 'brand' : 'chip'} startIcon={<SVG src="dollar"/>} onClick={handleSavePaymentID} dataCy="filtersMenuPaymentIDButton">
              {t("selected_payments")}
            </NormalButton>
          ) : null
        }
        {
          filtersData.filtersSettings.isAllowedPostType ? (
            <>
              <NormalButton className={classes.button} buttonType={filtersData.filterParams.postTypeID !== null ? 'brand' : 'chip'} startIcon={<SVG src="timeline"/>} onClick={() => setState("openPostTypesModal", true)} dataCy="filtersMenuPostTypeButton">
                {t("post_type")}
              </NormalButton>
              {
                state.openPostTypesModal ? (
                  <FiltersPostTypesModal
                    onClose={() => handleModalState("PostTypes", false)}
                    onSave={handleSavePostTypesModal}
                    selected={filtersData.filterParams.postTypeID}  
                  />
                ) : null
              }
            </>
          ) : null
        }
        {
          filtersData.filtersSettings.isAllowedGalleryType ? (
            <>
              <NormalButton className={classes.button} buttonType={filtersData.filterParams.galleryTypeID.length !== 0 ? 'brand' : 'chip'} startIcon={<SVG src="photo-archive"/>} onClick={() => setState("openGalleryTypesModal", true)} dataCy="filtersMenuGalleryTypeButton">
                {t("gallery_type")}
                {
                  filtersData.filterParams.galleryTypeID.length > 0 ? (
                    <span className={classes.chipIcon}>{filtersData.filterParams.galleryTypeID.length}</span>
                  ) : null
                }
              </NormalButton>
              {
                state.openGalleryTypesModal ? (
                  <FiltersGalleryTypesModal
                    onClose={() => handleModalState("GalleryTypes", false)}
                    onSave={handleSaveGalleryTypesModal}
                    selected={filtersData.filterParams.galleryTypeID}
                  />
                ) : null
              }
            </>
          ) : null
        }
        {
          filtersData.filtersSettings.isAllowedActivityType ? (
            <>
              <NormalButton className={classes.button} buttonType={filtersData.filterParams.activityTypeID.length > 0 ? 'brand' : 'chip'} startIcon={<SVG src="activity-type"/>} onClick={() => setState("openActivityTypesModal", true)} dataCy="filtersMenuActivityTypeButton">
                {t("activity")}
                {
                  filtersData.filterParams.activityTypeID.length > 0 ? (
                    <span className={classes.chipIcon}>{filtersData.filterParams.activityTypeID.length}</span>
                  ) : null
                }
              </NormalButton>
              {
                state.openActivityTypesModal ? (
                  <FiltersActivityTypesModal
                    onClose={() => handleModalState("ActivityTypes", false)}
                    onSave={handleSaveActivityModal}
                    selected={filtersData.filterParams.activityTypeID}          
                  />
                ) : null
              }
            </>
          ) : null
        }
        {
          (filtersData.filtersSettings.isAllowedChildID && dataData.children.length > 0) ? (
            <>
              <NormalButton className={classes.button} buttonType={filtersData.filterParams.childID.length > 0 ? 'brand' : 'chip'} startIcon={<SVG src="people"/>} onClick={() => setState("openChildrenModal", true)} dataCy="filtersMenuChildrenButton">
                {t("children")}
                {
                  removeDuplicatesJSON(filtersData.filterParams.childID).length > 0 ? (
                    <span className={classes.chipIcon}>{removeDuplicatesJSON(filtersData.filterParams.childID).length}</span>
                  ) : null
                }
              </NormalButton>
              {
                state.openChildrenModal ? (
                  <ChildrenSelect
                    isInModal={true}
                    isModalOpen={true}
                    modalTitle="children"
                    modalOnClose={() => handleModalState("Children", false)}
                    modalOnSave={handleSaveChildrenModal}
                    selectedChildren={filtersData.filterParams.childID.map((item: any) => { return {childID: item}; })}
                    isSelectAll={true}
                    isSelectInAllClass={true}
                    isMultipleSelect={true}
                    isShowChildrenArchived={true}
                    isAllowArchivedToggle={true}
                    isAllowSearch={true}
                    mode="select"
                    modalAllowCancel={false}
                    modalAllowClose={true}
                    modalAllowClear={true}
                    modalAllowNoChild={true}
                    modalAllowChildrenCount={false}
                  />
                ) : null
              }
            </>
          ) : null
        }
        {
          (filtersData.filtersSettings.isAllowedEmployeeID && dataData.employees.length > 0) ? (
            <>
              <NormalButton className={classes.button} buttonType={filtersData.filterParams.employeeID.length > 0 ? 'brand' : 'chip'} startIcon={<SVG src="employees"/>} onClick={() => setState("openEmployeesModal", true)} dataCy="filtersMenuEmployeesButton">
                {t("employees")}
                {
                  removeDuplicatesJSON(filtersData.filterParams.employeeID).length > 0 ? (
                    <span className={classes.chipIcon}>{removeDuplicatesJSON(filtersData.filterParams.employeeID).length}</span>
                  ) : null
                }
              </NormalButton>
              {
                state.openEmployeesModal ? (
                  <EmployeesSelect
                    isInModal={true}
                    isModalOpen={true}
                    modalTitle="children"
                    modalOnClose={() => handleModalState("Employees", false)}
                    modalOnSave={handleSaveEmployeesModal}
                    selectedEmployees={filtersData.filterParams.employeeID.map((item: any) => { return {employeeID: item}; })}
                    isSelectAll={true}
                    isSelectInAllSchool={true}
                    isMultipleSelect={true}
                    isShowEmployeesArchived={true}
                    isAllowArchivedToggle={true}
                    isAllowSearch={true}
                    mode="select"
                    modalAllowCancel={false}
                    modalAllowClose={true}
                    modalAllowClear={true}
                    modalAllowNoEmployee={true}
                    modalAllowEmployeesCount={false}
                  />
                ) : null
              }
            </>
          ) : null
        }
        {
          filtersData.filtersSettings.isAllowedDate ? (
            <>
              <NormalButton className={classes.button} buttonType={(filtersData.filterParams.dateFrom !== null || filtersData.filterParams.dateTo !== null) ? 'brand' : 'chip'} startIcon={<SVG src="calendar"/>} onClick={() => setState("openDatesModal", true)} dataCy="filtersMenuDatesButton">
                {(filtersData.filterParams.dateFrom !== null && filtersData.filterParams.dateTo !== null && !moment(filtersData.filterParams.dateFrom).isSame(moment(filtersData.filterParams.dateTo),'day')) ? dateFormat(moment(filtersData.filterParams.dateFrom), "default", languageData, t) + ' — ' + dateFormat(moment(filtersData.filterParams.dateTo), "default", languageData, t) : null}
                {(filtersData.filterParams.dateFrom !== null && filtersData.filterParams.dateTo !== null && moment(filtersData.filterParams.dateFrom).isSame(moment(filtersData.filterParams.dateTo),'day')) ? dateFormat(moment(filtersData.filterParams.dateFrom), "default", languageData, t) : null}
                {(filtersData.filterParams.dateFrom === null && filtersData.filterParams.dateTo !== null) ? dateFormat(moment(filtersData.filterParams.dateTo), "default", languageData, t) : null}
                {(filtersData.filterParams.dateFrom !== null && filtersData.filterParams.dateTo === null) ? dateFormat(moment(filtersData.filterParams.dateFrom), "default", languageData, t) : null}
                {(filtersData.filterParams.dateFrom === null && filtersData.filterParams.dateTo === null) ? t("date") : null}
              </NormalButton>
              {
                state.openDatesModal ? (
                  <FiltersDatesModal
                    onClose={() => handleModalState("Dates", false)}
                    onSave={handleSaveDatesModal}
                    selectedDateFrom={filtersData.filterParams.dateFrom}
                    selectedDateTo={filtersData.filterParams.dateTo}          
                  />
                ) : null
              }
            </>
          ) : null
        }
        {
          (filtersData.filtersSettings.isAllowedAuthors && dataData.users.length > 0) ? (
            <>
              <NormalButton className={classes.button} buttonType={filtersData.filterParams.authorID.length > 0 ? 'brand' : 'chip'} startIcon={<SVG src="person"/>} onClick={() => setState("openAuthorsModal", true)} dataCy="filtersMenuAuthorsButton">
                {t("author")}
                {
                  filtersData.filterParams.authorID.length > 0 ? (
                    <span className={classes.chipIcon}>{filtersData.filterParams.authorID.length}</span>
                  ) : null
                }
              </NormalButton>
              {
                state.openAuthorsModal ? (
                  <UsersSelect
                    isInModal={true}
                    isModalOpen={true}
                    modalTitle="authors"
                    modalOnClose={() => handleModalState("Authors", false)}
                    modalOnSave={handleSaveAuthorsModal}
                    defaultSchools={filtersData.filterParams.schoolID.length === 0 ? listSchools.map((item: any) => { return { schoolID: item.schoolID }; }) : filtersData.filterParams.schoolID.map((item: any) => { return { schoolID: item }; })}
                    defaultUsers={dataData.users.filter((item: any) => item.roleType !== 3 && item.userID > 0)}
                    selectedUsers={filtersData.filterParams.authorID.map((item: any) => { return {userID: item}; })}
                    isSelectAll={true}
                    isSelectInAllSchool={true}
                    isMultipleSelect={true}
                    isAllowSearch={true}
                    mode='select'
                    modalAllowCancel={false}
                    modalAllowClose={true}
                    modalAllowClear={true}
                    modalAllowNoUser={true}
                    modalAllowUsersCount={false}
                />
                ) : null
              }
            </>
          ) : null
        }
        {
          filtersData.filtersSettings.isAllowedPolls ? (
            <>
              <NormalButton className={classes.button} buttonType={filtersData.filterParams.postTypeID === 1 ? 'disabled' : filtersData.filterParams.pollVoted !== null ? 'brand' : 'chip'} startIcon={<SVG src="polls"/>}  onClick={() => setState("openPollsModal", true)} disabled={filtersData.filterParams.postTypeID === 1 ? true : false} dataCy="filtersMenuPollsButton">
                {t("polls")}
              </NormalButton>
              {
                state.openPollsModal ? (
                  <FiltersPollsModal
                    onClose={() => handleModalState("Polls", false)}
                    onSave={handleSavePollsModal}
                    selected={filtersData.filterParams.pollVoted}         
                  />
                ) : null
              }
            </>
          ) : null
        }
        {
          (filtersData.filtersSettings.isAllowedClasses && dataData.classes.length > 0) ? (
            <>
              <NormalButton className={classes.button} buttonType={filtersData.filterParams.classID.length > 0 ? 'brand' : 'chip'} startIcon={<SVG src="class"/>} onClick={() => setState("openClassesModal", true)} dataCy="filtersMenuClassesButton">
                {t("classes")}
                {
                  filtersData.filterParams.classID.length > 0 ? (
                    <span className={classes.chipIcon}>{filtersData.filterParams.classID.length}</span>
                  ) : null
                }
              </NormalButton>
              {
                state.openClassesModal ? (
                  <ClassesSelect
                    isInModal={true}
                    isModalOpen={true}
                    modalTitle="classes"
                    modalOnClose={() => handleModalState("Classes", false)}
                    modalOnSave={handleSaveClassesModal}
                    defaultSchools={filtersData.filterParams.schoolID.length === 0 ? listSchools.map((item: any) => { return { schoolID: item.schoolID }; }) : filtersData.filterParams.schoolID.map((item: any) => { return { schoolID: item }; })}
                    defaultClasses={listClasses.map((item: any) => { return { classID: item.classID, schoolID: item.classID }; })}
                    selectedClasses={filtersData.filterParams.classID.map((item: any) => { return {classID: item}; })}
                    isSelectAll={true}
                    isMultipleSelect={true}
                    isAllowSearch={true}
                    isShowChildrenCounts={true}
                    mode="select"
                    modalAllowCancel={false}
                    modalAllowClose={true}
                    modalAllowClear={true}
                    modalAllowNoClass={true}
                    modalAllowClassesCount={false}
                  />
                ) : null
              }
            </>
          ) : null
        }
        {
          (filtersData.filtersSettings.isAllowedSchools && dataData.schools.length > 0 && dataData.classes.length > 0) ? (
            <>
              <NormalButton className={classes.button} buttonType={filtersData.filterParams.schoolID.length > 0 ? 'brand' : 'chip'} startIcon={<SVG src="school"/>} onClick={() => setState("openSchoolsModal", true)} dataCy="filtersMenuSchoolsButton">
                {t("schools")}
                {
                  filtersData.filterParams.schoolID.length > 0 ? (
                    <span className={classes.chipIcon}>{filtersData.filterParams.schoolID.length}</span>
                  ) : null
                }
              </NormalButton>
              {
                state.openSchoolsModal ? (
                  <SchoolsSelect
                    isInModal={true}
                    isModalOpen={true}
                    modalTitle="schools"
                    modalOnClose={() => handleModalState("Schools", false)}
                    modalOnSave={handleSaveSchoolsModal}
                    defaultSchools={filtersData.filterParams.classID.length === 0 ? listSchools.map((item: any) => { return { schoolID: item.schoolID }; }) : listSchools.map((theSchool: any) => { const schoolSelectedClasses = filtersData.filterParams.classID.map((theClass: any) => { if(listClasses.filter((item: any) => item.classID === theClass && item.schoolID === theSchool.schoolID).length !== 0) { return theClass.classID; } else { return null; }}).filter((item: any) => item !== null); const schoolClasses = listClasses.filter((item: any) => item.schoolID === theSchool.schoolID).map((item: any) => { return { classID: item.classID}; }); return { schoolID: theSchool.schoolID, schoolSelectedClasses: schoolSelectedClasses, schoolClasses: schoolClasses };}).filter((item: any) => item.schoolClasses.length !== 0 && (item.schoolSelectedClasses.length !== 0 || filtersData.filterParams.classesID.length === 0)).map((item: any) => { return { schoolID: item.schoolID }; })}
                    selectedSchools={filtersData.filterParams.schoolID.map((item: any) => { return {schoolID: item}; })}
                    isMultipleSelect={true}
                    isAllowSearch={true}
                    mode="select"
                    modalAllowCancel={false}
                    modalAllowClose={true}
                    modalAllowClear={true}
                    modalAllowNoSchool={true}
                    modalAllowSchoolsCount={false}
                  />
                ) : null
              }
            </>
          ) : null
        }
        {
          filtersData.filtersSettings.isAllowedTimelineFavorite ? (
            <NormalButton className={classes.button} buttonType={filtersData.filterParams.timelineFavorite !== null ? 'brand' : 'chip'} startIcon={<SVG src="bookmark-heart"/>} onClick={handleSaveTimelineFavorite} dataCy="filtersMenuTimelineFavoriteButton">
              {t("bookmarked")}
            </NormalButton>
          ) : null
        }
        {
          filtersData.filtersSettings.isAllowedGalleryFavorite ? (
            <NormalButton className={classes.button} buttonType={filtersData.filterParams.galleryFavorite !== null ? 'brand' : 'chip'} startIcon={<SVG src="star"/>} onClick={handleSaveGalleryFavorite} dataCy="filtersMenuGalleryFavoriteButton">
              {t("favorite")}
            </NormalButton>
          ) : null
        }
        {
          filtersData.filtersSettings.isAllowedGalleryHide ? (
            <NormalButton className={classes.button} buttonType={filtersData.filterParams.galleryHide !== null ? 'brand' : 'chip'} startIcon={<SVG src="eye-slash"/>} onClick={handleSaveGalleryHide} dataCy="filtersMenuGalleryHideButton">
              {t("hidden")}
            </NormalButton>
          ) : null
        }
        {
          filtersData.filtersSettings.isAllowedPaymentMethod ? (
            <Select inputLabel={t("payment_method")} items={configurationData.configuration.paymentMethods} selected={filtersData.filterParams.paymentMethod} setSelected={handleSavePaymentMethod} width={220}  dataCy="filtersMenuPaymentMethodSelect"/>
          ) : null
        }
        {
          filtersData.filtersSettings.isAllowedPaymentStatus ? (
            <Select inputLabel={t("payment_status")} items={configurationData.configuration.paymentStatuses} selected={filtersData.filterParams.paymentStatus} setSelected={handleSavePaymentStatus} width={220}  dataCy="filtersMenuPaymentStatusSelect"/>
          ) : null
        }
        {
          filtersData.filtersSettings.isAllowedPaymentType ? (
            <Select inputLabel={t("payment_type")} items={configurationData.configuration.paymentTypes} selected={filtersData.filterParams.paymentType} setSelected={handleSavePaymentType} width={220}  dataCy="filtersMenuPaymentTypeSelect"/>
          ) : null
        } 
        {
          filtersData.filtersSettings.isAllowedCurrencyID ? (
            <Select inputLabel={t("currency")} items={configurationData.configuration.currencies} selected={filtersData.filterParams.currencyID} setSelected={handleSaveCurrency} width={180} defaultTitle="iso" dataCy="filtersMenuCurrencySelect"/>
          ) : null
        }
      </ScrollMenu>
    </div>
  ) : null;
}

export default Filters;