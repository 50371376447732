import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { getQueryString } from 'src/utils/useFunctions';
import { setCommunicationViewMode } from 'src/store/actions/communication.actions';
import { setWarningModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    backgroundColor: theme.colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: '8px',
    '& > div': {
      position: 'relative',
      '& > svg': {
        width: '9.375rem',
        height: '9.375rem',
      },
      '& > span': {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: '-0.25rem',
        right: '-0.5rem',
        width: '3rem',
        height: '3rem',
        backgroundColor: theme.colors.white,
        borderRadius: '100%',
        boxShadow: theme.shadows[2],
        '& > svg': {
          width: '2rem',
          height: '2rem',
        },
      },
    },
    '& > span': {
      fontSize: '20px',
      fontWeight: '500',
    },
    '& > button': {
      '& svg': {
        width: '24px',
        height: '24px',
      },
    },
  },
}));

const Default: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const communicationData = useAppSelector((state: any) => state.communication);
  const userData = useAppSelector((state: any) => state.user);

  const queryString = getQueryString(location);
  const customViewMode = communicationData.viewMode;
  const cantCreateNewThread = userData.userObject.schoolSetting.every((schoolSetting: any) => schoolSetting.modules && schoolSetting.modules.communication && schoolSetting.modules.communication.banned === true);

  const handleNavigate = (loc: any) => {
    if(customViewMode) {
      dispatch(setCommunicationViewMode(loc));
    } else {
      navigate(`/communication/${loc}${queryString}`);
    }
  };

  const handleNewMessage = () => {
    if(cantCreateNewThread) {
      const settings = {
        isOpen: true,
        title: t('function_unavailable'),
        content: t('function_unavailable_communication_new'),
      };
      dispatch(setWarningModal(settings));
      return;
    }
    handleNavigate("new");
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div>
          <SVG src="webapp-logo"/>
          <span>
            <SVG src="communication"/>
          </span>
        </div>
        <span>{t('communication_title')}</span>
        <p>{t('communication_description')}</p>
        <NormalButton startIcon={<SVG src="pencil-new"/>} onClick={handleNewMessage}>{t('communication_button')}</NormalButton>
      </div>
    </div>
  );
};

export default Default;