import Attendance from '../../../components/Attendance';
import DateCarouselSelect from '../../../components/Selects/DateCarouselSelect';
import LeavePageBlocker from '../../../components/Layouts/LeavePageBlocker';
import moment from '../../../utils/moment';
import NormalButton from '../../../components/Buttons/NormalButton';
import React, { useCallback, useRef } from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { setAttendanceDate, setAttendanceSelectedChildren } from '../../../store/actions/attendance.actions';
import { setWarningModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';
import { getQueryString } from 'src/utils/useFunctions';

const useStyles = createUseStyles((theme: any) => ({
  attendancePage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '24px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  attendanceWrapper: {
    display: 'flex',
    width: '75%',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
      maxWidth: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  buttons: {
    position: 'fixed',
    bottom: '60px',
    display: 'flex',
  },
  saveButton: {
    borderRadius: '10px 0 0 10px',
    minWidth: 'auto',
    borderTopWidth: '2px',
    borderLeftWidth: '2px',
    borderBottomWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
  },
  cancelButton: {
    borderRadius: '0 10px 10px 0',
    minWidth: 'auto',
    borderTopWidth: '2px',
    borderRightWidth: '2px',
    borderBottomWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.white,
    '& > span': {
      height: '16px',
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const attendanceData = useAppSelector((state: any) => state.attendance);
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const attendanceService = useAppSelector((state: any) => state.services).attendanceService;
  const date = attendanceData.date;
  const attendanceWrapper: any = useRef(null);
  const attendanceWrapperCurrent = attendanceWrapper.current;
  const activeClasses = dataData.classes.filter((theclass: any) => theclass.isArchived === false && theclass.active).length;
  const selectedChildren = attendanceData.selectedChildren;
  const windowHandler: any = window;

  const queryString = getQueryString(location);
  
  const [state, setState] = useStates({
    isLoaded: false,
    isReloaded: false,
    dateCarouselSelectWidth: attendanceWrapperCurrent ? attendanceWrapperCurrent.clientWidth : 0,
    classesList: [],
    childrenList: [],
    presentChildren: [],
    canSave: true,
  });

  const getClassData = useCallback((classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? {} : dataData.classes.find((item: any) => item.classID === classID);
  }, [dataData.classes]);

  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? {} : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const loadData = useCallback((isReload: any) => {
    attendanceService && attendanceService.listAttendance(moment(date).format('YYYY-MM-DD'), languageData.language.toLowerCase()).then((result: any) => {
      const newClassesList = result.data.classes.map((item: any) => {
        return {
          classID: item.classID,
          classAttendanceStatus: item.classAttendanceStatus,
          presentCount: item.presentCount,
          missingCount: item.missingCount,
          expectedAfternoonCount: item.expectedAfternoonCount,
          expectedMorningCount: item.expectedMorningCount,
          schoolID: getClassData(item.classID).schoolID,
          name: getClassData(item.classID).name,
          children: item.children,
        };
      });
      const newChildrenList = result.data.classes.map((item: any) => {
        return item.children.map((child: any) => {
          if(Object.keys(getChildData(child.childID)).length !== 0) {
            return {...child, classID: [item.classID], schoolID: getChildData(child.childID).schoolID};
          } else {
            return null;
          }
        }).filter((item: any) => item !== null);
      }).flat();
      const presentChildren = newChildrenList.filter((item: any) => item.isPresent).map((item: any) => { return { childID: item.childID, classID: item.classID[0] }; })
      setState("classesList", newClassesList);
      setState("childrenList", newChildrenList);
      setState("presentChildren", presentChildren);
      setState("isReloaded", true);
      if(isReload) {
        setTimeout(() => {
          setState("isReloaded", false);
          setState("isLoaded", true);
        }, 100);
      } else {
        setState("isReloaded", false);
        setState("isLoaded", true);
      }
    });
  }, [attendanceService, date, languageData.language, setState, getClassData, getChildData]); 
  
  useEffect(() => {
    setState("isLoaded", false);
    loadData(false);
  }, [setState, loadData], [date, languageData.language]);

  useEffect(() => {
    windowHandler.isAttendanceReady = state.isLoaded;
  }, [windowHandler, state.isLoaded], [state.isLoaded]);

  useEffect(() => {
    setState("dateCarouselSelectWidth", attendanceWrapper.current.clientWidth);  
  }, [browserData.width, setState], [browserData.width]);

  useEffect(() => {
    if(activeClasses === 0) {
      const settings = {
        isOpen: true,
        title: t('function_unavailable'),
        content: t('function_unavailable_attendance'),
      };
      dispatch(setWarningModal(settings));
      navigate(`/${queryString}`); 
    }
    return () => {
      dispatch(setAttendanceDate(moment()));
      dispatch(setAttendanceSelectedChildren([]));
    } 
  }, [activeClasses, dispatch, navigate, t, queryString], []);
  
  const handleSave = () => {
    if(state.canSave) {
      setState("canSave", false);
      const updateChildren = selectedChildren.map((item: any) => {
        return { childID: item.childID, classID: item.classID, isPresent: item.isPresent };
      });
      let payload = {children: updateChildren};
      attendanceService && attendanceService.updateAttendance(payload).then((result: any) => {
        if(result.status === 200) {
          createNotification(t("attendance_saved"), "success");
          dispatch(setAttendanceSelectedChildren([]));
          loadData(true);
        } else {                       
          createNotification(t("attendance_not_saved"), "error");
        }
        setState("canSave", true);
      }).catch(() => {
        createNotification(t("attendance_not_saved"), "error");
        setState("canSave", true);
      });
    }
  };
  
  const handleClear = () => {
    setState("isReloaded", true);
    dispatch(setAttendanceSelectedChildren([]));
    loadData(false);
  };
  
  return (
    <div className={classes.attendancePage}>
      <LeavePageBlocker when={selectedChildren.length > 0} />
      <div className={classes.attendanceWrapper} ref={attendanceWrapper}>
        <DateCarouselSelect width={state.dateCarouselSelectWidth}/>
      </div>
      <Attendance isLoaded={state.isLoaded} isReloaded={state.isReloaded} presentChildren={state.presentChildren} classesList={state.classesList} childrenList={state.childrenList}/>
      {
        selectedChildren.length !== 0 ? (
          <div className={classes.buttons}>
            <NormalButton buttonType="primary" className={classes.saveButton} onClick={handleSave} dataCy="attendanceSaveButton">
              {t('save_attendance')}
            </NormalButton>
            <NormalButton buttonType="clear" className={classes.cancelButton} onClick={handleClear} dataCy="attendanceClearButton">
              <SVG src="close"/>
            </NormalButton>
          </div>
        ) : null
      } 
    </div>
  );
};

export default PageTemplate;