import ChildCard from '../../../components/Cards/ChildCard';
import ChildrenTable from 'src/components/Tables/ChildrenTable';
import EndOfScroll from 'src/components/Layouts/EndOfScroll';
import Masonry from 'react-masonry-component';
import NotFound from 'src/components/Layouts/NotFound';
import React, { useCallback, useRef } from 'react';
import tabs from 'src/constants/tabs';
import TabsMenu from 'src/components/Menus/TabsMenu';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getFiltersKeys, getFiltersKeysArray, getQueryString } from 'src/utils/useFunctions';
import { getUserRole, getUserSetting, saveUserSettings } from '../../../utils/useUser';
import { resetFilterParams, setFilterParams, setFiltersSettings, setIsFilterParamsLoaded, setIsFilterSettingsLoaded } from 'src/store/actions/filters.actions';
import { setChildrenViewMode } from 'src/store/actions/children.actions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useParams } from 'react-router';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  childrenPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '24px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    maxHeight: '100%',
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      minHeight: '24px',
      height: '24px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0',
      width: '100%',
      maxWidth: '100%',
    },
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    '& > div': {
      width: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    paddingTop: '16px',
    [theme.breakpoints.down('md')]: {
      height: 'unset !important',
      maxHeight: 'unset !important',
    },
  },
  childCardWrapper: {
    width: '400px',
    maxWidth: '100%',
    marginBottom: '20px',
    borderRadius: '24px',
  },
  masonryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
  },
  masonry: {
    width: '100%',
    maxWidth: '100%',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    width: '100%',
    paddingBottom: '100px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const { tab } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const dataData = useAppSelector((state: any) => state.data);
  const filtersData = useAppSelector((state: any) => state.filters);
  const childrenData = useAppSelector((state: any) => state.children);
  const userData = useAppSelector((state: any) => state.user);

  const queryString = getQueryString(location);
  
  const childrenScroll: any = useRef(null);
  const tabsItems = tabs.children.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));

  const viewMode = childrenData.viewMode;
  const containerWidth = childrenScroll.current ? childrenScroll.current.clientWidth : 0;

  const classes = useStyles();

  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const getLimit = useCallback(() => {
    const count = (Math.floor(containerWidth / 400)) * 2;
    return count === 0 ? 1 : count;
  }, [containerWidth]);

  const [state, setState] = useStates({
    isFirstTime: true,
    isLoading: true,
    isLoadingMore: false,
    page: 1,
  });

  const filtersParams = getFiltersKeys(filtersData.filterParams, {});
  const childrenIds = filtersParams.childID ? filtersParams.childID.map((item: any) => { return parseInt(item); }) : [];

  const childrenTotal = childrenIds.filter((item: any) => getChildData(item).length !== 0);

  const childrenList = childrenTotal.slice(0, state.page * getLimit());

  const onScroll = () => {
    if(childrenScroll.current) {
      const { scrollTop, scrollHeight, clientHeight }: any = childrenScroll.current;
      if(scrollTop + clientHeight > scrollHeight - 100) {
        if(!state.isLoadingMore && childrenList.length < childrenTotal.length) {
          setState("isLoadingMore", true);
          setTimeout(() => {
            setState("isLoadingMore", false);
            setState("page", state.page + 1);
          }, 500);
        };
      }
    }
  };

  const setViewMode = (value: any) => {
    setState("page", 1);
    dispatch(setChildrenViewMode(value));
    window.history.replaceState({}, '', `/children/${value}${queryString}`);
  };

  const saveFilters = useCallback(async (filterParam: any) => {
    if(getUserSetting(userData.userSettings, "addons", ["children", "children_filters_save"])) {
      const filtersParams = getFiltersKeysArray(filterParam, {});
      await saveUserSettings(dispatch, userData, "filters", ["children"], filtersParams);
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if(tab) {
      if(tabsItems.filter((item: any) => item.value === tab).length === 0) {
        const value = tabsItems[0].value;
        dispatch(setChildrenViewMode(value));
        window.history.replaceState({},'',`/children/${value}${queryString}`);
      } else {
        dispatch(setChildrenViewMode(tab));
        window.history.replaceState({},'',`/children/${tab}${queryString}`);
      }
    } else {
      dispatch(setChildrenViewMode(tabsItems[0].value));
      window.history.replaceState({}, '', `/children/${tabsItems[0].value}${queryString}`);
    }
    return () => {
      dispatch(setChildrenViewMode(null));
    }
  }, [dispatch, tabsItems, tab, queryString], [tab]);

  useEffect(() => {
    if(filtersData.isFilterSetupLoaded && !filtersData.isFilterSettingsLoaded && !filtersData.isFilterParamsLoaded) {
      const settings = {
        isAllowedPostID: false,
        isAllowedPaymentID: false,
        isAllowedPostType: false,
        isAllowedGalleryType: false,
        isAllowedActivityType: false,
        isAllowedChildID: true,
        isAllowedEmployeeID: false,
        isAllowedDate: false,
        isAllowedAuthors: false,
        isAllowedPolls: false,
        isAllowedClasses: false,
        isAllowedSchools: false,
        isAllowedTimelineFavorite: false,
        isAllowedGalleryFavorite: false,
        isAllowedGalleryHide: false,
        isAllowedPaymentMethod: false,
        isAllowedPaymentStatus: false,
        isAllowedPaymentType: false,
        isAllowedCurrencyID: false,
        isAllowedArchived: false,
      };
      dispatch(setFiltersSettings(settings));
      setTimeout(() => {
        dispatch(setIsFilterSettingsLoaded(true));
      }, 100);
    }
  }, [dispatch, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded]);

  useEffect(() => {
    if(!filtersData.isFilterParamsLoaded && filtersData.isFilterSettingsLoaded) {
      if(getUserSetting(userData.userSettings, "addons", ["children", "children_filters_save"])) {
        const customFilters = getUserSetting(userData.userSettings, "filters", ["children"]);
        if(Array.isArray(customFilters) && customFilters.length > 0) {
          customFilters.forEach((item: any) => {
            dispatch(setFilterParams({[item.key]: item.value}));
          });
          if(getUserSetting(userData.userSettings, "addons", ["app", "app_filter_autoopening"])) {
            dispatch(setIsFiltersVisible(true));
          }
        } else {
          const children = dataData.children.filter((item: any) => !item.isArchived && item.isInAnyActiveClass).map((item: any) => { return item.childID; });
          dispatch(setFilterParams({childID: children}));
        }
      } else {
        const children = dataData.children.filter((item: any) => !item.isArchived && item.isInAnyActiveClass).map((item: any) => { return item.childID; });
        dispatch(setFilterParams({childID: children}));
      }
      dispatch(setIsFilterParamsLoaded(true));
      setState("isLoading", false);
    }
  }, [dispatch, setState, dataData.children, userData.userSettings, filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded,], [filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded]);

  useEffect(() => {
    dispatch(setIsFilterParamsLoaded(false));
    dispatch(setIsFilterSettingsLoaded(false));
    return () => {
      dispatch(setIsFilterParamsLoaded(false));
      dispatch(setIsFilterSettingsLoaded(false));
      dispatch(resetFilterParams());
    }
  }, [dispatch], []);

  useEffect(() => {
    if(filtersData.isFilterParamsLoaded && filtersData.isFilterSetupLoaded && filtersData.isFilterSettingsLoaded && !state.isFirstTime) {
      saveFilters(filtersData.filterParams);
    } else {
      setState("isFirstTime", false);
    }
  }, [saveFilters, setState, state.isFirstTime, filtersData.filterParams, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.filterParams]);

  return (
    <div className={classes.childrenPage} onScroll={onScroll} ref={childrenScroll}>
      <div className={classes.tabsWrapper}>
        <TabsMenu items={tabsItems} selected={viewMode} onSelect={setViewMode}/>
      </div>
      <div className={classes.wrapper}>
        {
          state.isLoading ? (
            <div className={classes.spinner}>
              <CircularProgress/>
            </div>
          ) : (
            <>
              {
                viewMode === "cards" ? (
                  <>
                    {
                      childrenList.length === 0 ? (
                        <NotFound title={t('no_children')} text={t('no_children_found')}/>
                      ) : (
                        <div className={classes.masonryWrapper}>
                          <Masonry className={classes.masonry} options={{columnWidth: 400, gutter: 25, fitWidth: true, horizontalOrder: true}}>
                            {
                              childrenList.map((childID: any, key: any) => {
                                return (
                                  <div className={classes.childCardWrapper} key={`k_${key}`}>
                                    <ChildCard childID={childID}/>
                                  </div>
                                );               
                              })
                            }
                          </Masonry>
                          {
                            state.isLoadingMore ? (
                              <div className={classes.spinner}>
                                <CircularProgress/>
                              </div>
                            ) : null
                          }
                          {
                            childrenList.length >= childrenTotal.length ? ( 
                              <EndOfScroll text={t("no_more_children")}/>
                            ) : null
                          }
                        </div>
                      )
                    }
                  </>
                ) : viewMode === "table" ? (
                  <ChildrenTable childrenID={childrenTotal}/>
                ) : null
              }
            </>
          )
        }
      </div>
    </div>
  );
};

export default PageTemplate;