import * as AuthService from '../../../services/auth.service';
import * as UserService from '../../../services/user.service';
import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import CheckboxInput from 'src/components/Forms/CheckboxInput';
import config from 'src/constants/config';
import httpResult from 'src/constants/httpresult';
import IconButton from 'src/components/Buttons/IconButton';
import Input from 'src/components/Forms/Input';
import Label from 'src/components/Forms/Label';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback, useRef } from 'react';
import Select from 'src/components/Forms/Select';
import SHA1 from 'src/services/crypto.service';
import Skeleton from '@mui/material/Skeleton';
import SVG from 'src/components/Images/SvgRenderer';
import { convertToCapitalFirstLetter, getQueryString, isExistInArrayWithObject, onlyUnique, parseEnviromentName } from 'src/utils/useFunctions';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getAppAccessToken, getAppApiServer, getAppData, getAppEnvironmentApiData, getCurrentApiServer, setAppAccessToken, setAppData } from 'src/utils/useApp';
import { getGitLabEnvironments } from 'src/services/gitlab.service';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { ObjectType } from 'src/types/types';
import { resetAttachments, resetImages, resetVideos } from 'src/store/actions/medias.actions';
import { setConfirmModal } from 'src/store/actions/modals.actions';
import { setInitializeUserController, setReinitializeUserController } from 'src/store/actions/controllers.actions';
import { setUserObject, setUserStatus } from 'src/store/actions/user.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  switchUserPage: {
    marginTop: '25px',
    marginBottom: '55px',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '24px',
    fontWeight: '600',
    fontSize: '24px',
  },
  loginFormWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  loginWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 400px)',
    justifyContent: 'center',
    gap: '20px',
    margin: '0 auto',
  },
  loginCard: {
    margin: 'auto',
    maxWidth: 'calc(400px - 96px)',
    width: '100%',
    padding: '24px 48px 32px 48px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100% - 96px)',
      marginBottom: '24px',
      padding: '16px 24px 24px 24px',
    },
  },
  loginCardTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '16px',
    fontWeight: 'bold',
    fontSize: '14px',
    color: theme.colors.black,
  },
  loginCardAvatarWrapper: {
    textAlign: 'center',
    marginTop: '16px',
  },
  loginCardAvatar: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '80px',
    backgroundColor: theme.colors.grey[250],
    borderRadius: '100%',
    overflow: 'hidden',
    '& + div': {
      marginLeft: '10px',
    },
    '& div': {
      position: 'relative',
      width: '80px',
      height: '80px',
    },
  },
  loginCardForm: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start', 
  },
  skeletonLabel: {
    marginTop: '16px',
    marginBottom: '7px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '16px',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& > button': {
      width: '100%',
      fontSize: '14px',
      '& svg': {
        width: '20px',
        height: '20px',
        marginRight: '5px',
      },
    },
  },
  currentUserEdit: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '32px',
    height: '32px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    backgroundColor: theme.colors.grey[200],
    '& > svg': {
      width: '16px',
      height: '16px',
    },
  },
  deleteButton: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '32px',
    height: '32px',
    backgroundColor: theme.colors.grey[200],
    '&:hover': {
      backgroundColor: theme.colors.grey[225],
    },
    '& > svg': {
      width: '16px',
      height: '16px',
    },
  },
  currentUser: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    cursor: 'auto',
    width: '32px',
    height: '32px',
    backgroundColor: theme.colors.grey[250],
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > svg': {
      width: '16px',
      height: '16px',
    },
  },
  expiredUser: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    cursor: 'auto',
    width: '32px',
    height: '32px',
    backgroundColor: theme.colors.systemRed[200],
    '&:hover': {
      backgroundColor: theme.colors.systemRed[200],
    },
    '& > svg': {
      width: '16px',
      height: '16px',
    },
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '6px',
    width: '100%',
    marginTop: '24px',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
      textDecoration: 'underline',
      fontSize: '14px',
      cursor: 'pointer',
    },
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '24px',
    gap: '8px',
    '& > button': {
      backgroundColor: theme.colors.primaryBlue[500],
      height: '48px',
      width: '48px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > svg': {
        color: theme.colors.white,
        width: '24px',
        height: '24px',
      },
      '&:hover': {
        backgroundColor: theme.colors.primaryBlue[600],
      },
      '&:disabled': {
        backgroundColor: theme.colors.grey[600],
      },
      '&.active': {
        backgroundColor: theme.colors.primaryBlue[700],
      },
    }
  },
  selectWrapper: {
    display: 'flex',
    marginBottom: '32px',
    justifyContent: 'center',
    '& > div': {
      width: '250px',
    },
  },
  select: {
    width: '100%',
    flex: 'unset',
  },
  environment: {
    position: 'absolute',
    top: '8px',
    left: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    fontWeight: '600',
    backgroundColor: theme.colors.chip,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: '20px',
    width: 'fit-content',
    padding: '0 4px',
    height: '20px',
  },
  checkbox: {
    marginTop: '16px',
    '& > span': {
      marginLeft: '-9px',
    },
  },
}));

const UserSwitch: React.FunctionComponent = () => {  

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const loadingData = useAppSelector((state: any) => state.loading);
  const userData = useAppSelector((state: any) => state.user);
  const savedData = getAppData();
  const navigate = useNavigate();
  const apiServers: ObjectType = config.API_SERVERS;
  const savedUsers = savedData.users ? savedData.users : [];
  const useApiServers = config.APP_ENVIRONMENT !== "production" && Object.keys(config.API_SERVERS).length;

  const apiServersList: any = Object.keys(apiServers).map((item: any) => {
    return {name: apiServers[item].name, url: apiServers[item].url};
  });

  const queryString = getQueryString(location);

  const users = savedData.users;

  if(users && users.length > 1) {
    users.sort((a: any) => { if((useApiServers ? (a.userID === userData.userObject.userID && a.apiServer === getAppApiServer()) : a.userID === userData.userObject.userID)) return -1; else return 1; });
  }
  
  const [state, setState] = useStates({
    isLoaded: false,
    isLoading: false,
    isFailed: false,
    environments: [],
    users: users,
    user: savedData.user,
    accessToken: getAppAccessToken(),
    loginUser: null,
    loginEmail: null,
    loginPassword: null,
    canEdit: false,
    canAdd: false,
    currentApiServer: savedData ? getCurrentApiServer(apiServersList,savedData.apiServer) : getCurrentApiServer(apiServersList, null),
    immediatelyLogin: true,
  });

  const tempUserData: any = useRef({
    accessToken: null,
    user: {},
  });

  const onEmailChange = (value: string) => {
    setState("loginEmail", value);
  };

  const onPasswordChange = (value: string) => {
    setState("loginPassword", value);
  };

  const handleBackToSwitch = () => {
    setState("loginUser", null);
    setState("loginPassword", null);
  };

  const handleLogout = (user: any) => {
    const newUsersData = state.users.length === 1 ? [] : state.users.filter((item: any) => { if(useApiServers) { return !(item.userID === user.userID && item.apiServer === user.apiServer); } else { return item.userID !== user.userID; }});
    const newData = {user: {}, users: newUsersData, savedPost: null, notifications: {...savedData.notifications, fcmToken: ""}, twigchat: {...savedData.twigchat, drafts: []}};
    setState("users", newUsersData);
    setAppData(newData);
    createNotification(t("switch_user_removed"), "success");
  };

  const handleDeleteUser = (user: any) => {
    const handleDeleteUserCallback = () => {
      const isSameUser = useApiServers ? (user.userID === userData.userObject.userID && user.apiServer === getAppApiServer()) : user.userID === userData.userObject.userID;
      if(isSameUser) {
        AuthService && AuthService.logout().then(() => {
          handleLogout(user);
        }).catch(() => {
          handleLogout(user);
        });
      } else {
        const newUsersData = state.users.length === 1 ? [] : state.users.filter((item: any) => { if(useApiServers) { return !(item.userID === user.userID && item.apiServer === user.apiServer); } else { return item.userID !== user.userID; }});
        setState("users", newUsersData);
        setAppData({users: newUsersData});
        createNotification(t("switch_user_removed"), "success");
      }
    };
    const settings = {
      isOpen: true,
      title: t('switch_user_remove_account'),
      content: t('switch_user_remove_account_confirm'),
      onAccept: handleDeleteUserCallback,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const handleContinueAsUser = (e: any, user: any) => {
    e.preventDefault();
    if(user.accessToken) {
      const isSameUser = useApiServers ? (user.userID === userData.userObject.userID && user.apiServer === getAppApiServer()) : user.userID === userData.userObject.userID;
      if(isSameUser && loadingData.isUserDataLoaded) {
        dispatch(resetAttachments());
        dispatch(resetImages());
        dispatch(resetVideos());
        navigate("/");
      } else {
        setState("isLoading", true);
        if(user.apiServer) {
          setAppData({apiServer: user.apiServer});
        }
        UserService && UserService.getUserBearerData(user.accessToken).then((result: any) => {
          if(result && result.data) {
            const newUserData = result.data.user;
            const userDataToStorage = {
              userID: newUserData.userID,
              email: newUserData.email,
              firstname: newUserData.firstname,
              surname: newUserData.surname,
              roleType: newUserData.roleType,
              photo: newUserData.photo.thumbLink,
            };
            setAppData({user: userDataToStorage});
            tempUserData.current = {
              accessToken: user.accessToken,
              user: userDataToStorage,
            };
            dispatch(setReinitializeUserController(true));
          }
        }).catch(() => {
          createNotification(t('switch_user_login_password'), 'error');
          const newUsersData = state.users.map((item: any) => {
            if(item.userID === user.userID) {
              return {...item, accessToken: false};
            } else {
              return item;
            }
          });
          setState("users", newUsersData);
          setAppData({users: newUsersData});
          setState("isLoading", false);
        });
      }
    } else {
      if(state.loginUser) {
        if(state.loginPassword) {
          const email = state.loginUser.email;
          const encrypt = SHA1(state.loginPassword);
          setState("isLoading", true);
          if(state.loginUser.apiServer) {
            setAppData({apiServer: state.loginUser.apiServer});
          }
          AuthService && AuthService.signIn(email, encrypt).then((result) => {
            if(result.data.authToken && result.data.user) {
              const authToken = result.data.authToken;
              const newUserData = result.data.user;
              const userDataToStorage = {
                userID: newUserData.userID,
                email: newUserData.email,
                firstname: newUserData.firstname,
                surname: newUserData.surname,
                roleType: newUserData.roleType,
                photo: newUserData.photo.thumbLink,
              };
              const newUsersData = state.users.map((item: any) => {
                const isSameUser = useApiServers ? (item.userID === state.loginUser.userID && item.apiServer === state.loginUser.apiServer) : item.userID === state.loginUser.userID;
                if(isSameUser) {
                  return {...item, accessToken: authToken};
                } else {
                  return item;
                }
              });
              setAppData({user: userDataToStorage, users: newUsersData});
              tempUserData.current = {
                accessToken: authToken,
                user: userDataToStorage,
              };
              dispatch(setReinitializeUserController(true));
            }
            setState("isLoading", false);
          }).catch((error) => {
            if(error.response) {
              if(error.response.status === httpResult.WRONG_USERNAME_OR_PASSWORD) {
                createNotification(t("email_or_password_invalid"), "error");
                setState("isLoading", false);            
              } else {
                createNotification(t("something_went_wrong"), "error");
                setState("isLoading", false);        
              }
            }
          });
        } else {
          createNotification(t("please_fill_fields"), "error");
        }
      } else {
        setState("loginUser", user);
      }
    }
  };

  useEffect(() => {
    if(userData.userStatus === "loggedOff" && Object.keys(tempUserData.current.user).length !== 0) {
      navigate(`/${queryString}`);
      setAppAccessToken(tempUserData.current.accessToken);
      dispatch(setUserObject(tempUserData.current.user));
      dispatch(setUserStatus("loggedIn"));
      dispatch(setInitializeUserController(true));
    }
  }, [dispatch, navigate, userData.userStatus, state.accessToken, tempUserData.current.user, queryString], [userData.userStatus, tempUserData.current.user]);

  const handleAddUser = (e: any) => {
    e.preventDefault();
    if(state.loginEmail && state.loginPassword) {
      const savedUsers = state.users ? state.users : [];
      if(!isExistInArrayWithObject(savedUsers, useApiServers ? ["email", "apiServer"] : "email", useApiServers ? [state.loginEmail, state.currentApiServer] : state.loginEmail)) {
        const email = state.loginEmail;
        const encrypt = SHA1(state.loginPassword);
        setState("isLoading", true);
        AuthService && AuthService.switchSignIn(state.currentApiServer.url, email, encrypt).then((result) => {
          if(result.data.authToken && result.data.user) {
            const authToken = result.data.authToken;
            const newUserData = result.data.user;
            const userDataToSave: any = {
              userID: newUserData.userID,
              email: newUserData.email,
              firstname: newUserData.firstname,
              surname: newUserData.surname,
              roleType: newUserData.roleType,
              photo: newUserData.photo.thumbLink,
              accessToken: authToken,
            };
            if(useApiServers) {
              userDataToSave.apiServer = state.currentApiServer.url;
            }
            const newSavedUsers = [...savedUsers, userDataToSave];
            setState("users", newSavedUsers);
            setAppData({users: newSavedUsers});
            if(state.immediatelyLogin) {
              handleContinueAsUser(e, userDataToSave);
              return;
            } else {
              setState("canAdd", false);
              createNotification(t("switch_user_added"), "success");
            }
          }
          setState("isLoading", false);
        }).catch((error) => {
          if(error.response) {
            if(error.response.status === httpResult.WRONG_USERNAME_OR_PASSWORD) {
              createNotification(t("email_or_password_invalid"), "error");
              setState("isLoading", false);            
            } else {
              createNotification(t("something_went_wrong"), "error");
              setState("isLoading", false);        
            }
          }
        });
      } else {
        createNotification(t("switch_user_already_exist"), "error");
      }
    } else {
      createNotification(t("please_fill_fields"), "error");
    }
  };

  const handleEdit = () => {
    setState("canEdit", !state.canEdit);
  };

  const handleAdd = () => {
    setState("canAdd", !state.canAdd);
    setState("immediatelyLogin", true);
  };

  const handleChangeApiServer = (api: any) => {
    setState("currentApiServer", getCurrentApiServer(apiServersList, api.url));
  };

  const handleChangeGitLabEnvironment = (environment: any) => {
    window.location.href = `${environment.url}${queryString}`;
  };

  const getCurrentGitLabEnvironment = useMemo(() => {
    if(state.environments.filter((item: any) => item.name.toLowerCase() === config.APP_ENVIRONMENT).length !== 0) {
      const environment = state.environments.find((item: any) => item.name.toLowerCase() === config.APP_ENVIRONMENT);
      const children = environment.children;
      if(children.length === 0) {
        return environment;
      } else if(children.length === 1) {
        return environment.children[0];
      } else {
        if(environment.children.filter((item: any) => item.slug === config.BRANCH).length !== 0) {
          return environment.children.find((item: any) => item.slug === config.BRANCH);
        } else {
          return environment;
        }
      }
    } else {
      return null;
    }
  }, [state.environments]);

  const verifyUser = useCallback((key: any) => {
    const user = state.users[key - 1];
    AuthService && AuthService.verifyToken(user.accessToken.toString(), user.apiServer).then(() => {
      if(key < state.users.length) {
        verifyUser(key + 1);
      } else {
        setState("isLoaded", true);
      }
    }).catch(() => {
      const newUsersData = state.users.map((item: any) => {
        const isSameUser = useApiServers ? (item.userID === user.userID && item.apiServer === user.apiServer) : item.userID === user.userID;
        if(isSameUser) {
          return {...item, accessToken: false};
        } else {
          return item;
        }
      });
      setState("users", newUsersData);
      setAppData({users: newUsersData});
      if(key < state.users.length) {
        verifyUser(key + 1);
      } else {
        setState("isLoaded", true);
      }
    });
  }, [state.users, setState, useApiServers]);

  const handleImmediatelyLogin = () => {
    setState("immediatelyLogin", !state.immediatelyLogin);
  };

  useEffect(() => {
    if((config.APP_ENVIRONMENT === "stage" || config.APP_ENVIRONMENT === "review") && config.GITLAB_KEY){
      getGitLabEnvironments().then((result: any) => {
        if(result) {
          if(result.data) {
            const enviromentsCategories = result.data.map((item: any) => {
              return convertToCapitalFirstLetter(item.name.split("/")[0]);
            }).filter(onlyUnique);
            const environments = enviromentsCategories.map((category: any) => {
              return { name: category, children: result.data.filter((item: any) => convertToCapitalFirstLetter(item.name.split("/")[0]) === category).map((item: any) => {
                const newName = item.name.split("/");
                newName.shift();
                return {
                  name: newName.length === 0 ? parseEnviromentName(item.name) : parseEnviromentName(newName.join(" ")),
                  slug: newName.length === 0 ? item.name : newName.join(" "),
                  url: item.external_url
                };
              })};
            });
            environments.sort((a: any, b: any) => {
              if (a.name === 'Stage' || a.name === 'Production') {
                return -1;
              } else if (b.name === 'Stage' || b.name === 'Production') {
                return 1;
              } else {
                if (a.name < b.name) {
                  return -1;
                } else if (a.name > b.name) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
            setState("environments", environments);
          }
        }
      }).catch(() => {
        createNotification(t('gitlab_environment_failed'), 'error');
      });
    }
  }, [setState, t], []);

  useEffect(() => {
    if(Array.isArray(state.users)) {
      if(state.users.length > 0) {
        verifyUser(1);
      } else {
        if(userData.userStatus === "loggedIn") {
          const savedUsers = state.users ? state.users : [];
          const currentAuthToken = getAppAccessToken();
          UserService && UserService.getUserBearerData(currentAuthToken).then((result: any) => {
            if(result && result.data) {
              const newUserData = result.data.user;
              const userDataToSave: any = {
                userID: newUserData.userID,
                email: newUserData.email,
                firstname: newUserData.firstname,
                surname: newUserData.surname,
                roleType: newUserData.roleType,
                photo: newUserData.photo.thumbLink,
                accessToken: currentAuthToken,
              };
              if(useApiServers) {
                userDataToSave.apiServer = getAppApiServer();
              }
              const newSavedUsers = [...savedUsers, userDataToSave];
              setState("users", newSavedUsers);
              setAppData({users: newSavedUsers});
              setState("isLoaded", true);
            }
          }).catch(() => {
            setState("isFailed", true);
          });
        }
      }
    }
  }, [state.users, verifyUser, setState, userData.userStatus, useApiServers], []);


  return savedUsers.length === 0 ? (
    <Navigate to={`/auth/login${queryString}`} state={{ from: location }}/>
  ) : (
    <div className={classes.switchUserPage}>
      <span className={classes.title}>{t('switch_users')}</span>
      <div className={state.canAdd ? classes.loginFormWrapper : classes.loginWrapper}>
        {
          state.isLoaded ? (
            <>
              {
                state.loginUser ? (
                  <div className={classes.loginCard}>
                    {
                      (useApiServers && state.loginUser.apiServer) ? (
                        <Tooltip title={getAppEnvironmentApiData(state.loginUser.apiServer).name} placement='bottom' arrow>
                          <span className={classes.environment}>{getAppEnvironmentApiData(state.loginUser.apiServer).shortName}</span>
                        </Tooltip>
                      ) : null
                    }
                    <div className={classes.loginCardTitle}>
                      {state.loginUser.firstname} {state.loginUser.surname}
                    </div>
                    <div className={classes.loginCardAvatarWrapper}>
                      <div className={classes.loginCardAvatar}>
                        <AuthenticatedImage thumbLink={state.loginUser.photo}/>
                      </div>
                    </div>
                    <form className={classes.loginCardForm} onSubmit={(e: any) => handleContinueAsUser(e, state.loginUser)}>
                      <Input value={state.loginUser.email} placeholder={t('email')} type="text" label={t('email') + '*'} name="email" useForm={false} readOnly={true}/>
                      <Input placeholder={t('password')} type="password" label={t('password') + '*'} name="password" onChange={onPasswordChange} useName={false} disabled={state.isLoading} tabIndex="1" dataCy="passwordInput" useForm={false} autoFocus={true}/>
                      <div className={classes.footer}>
                        <div className={classes.button}>
                          <NormalButton tabIndex="3" type="submit" buttonType="primary" dataCy="switchUserLoginButton" disabled={state.isLoading}>
                            {t("login")}
                          </NormalButton>
                        </div>
                        <div className={classes.bottom}>
                          <span onClick={handleBackToSwitch} data-cy={isCypress() ? "switchUserBackLink" : null}>{t('back')}</span>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : (
                  <>
                    {
                      !state.canAdd ? (
                        <>
                          {
                            state.users.map((user: any, key: any) => (
                              <div className={classes.loginCard} key={`k_${key}`} data-cy={isCypress() ? `loginCardUser${user.userID}` : null}>
                                {
                                  (useApiServers && user.apiServer) ? (
                                    <Tooltip title={getAppEnvironmentApiData(user.apiServer).name} placement='bottom' arrow>
                                      <span className={classes.environment}>{getAppEnvironmentApiData(user.apiServer).shortName}</span>
                                    </Tooltip>
                                  ) : null
                                }
                                {
                                  ((useApiServers ? (user.userID === userData.userObject.userID && user.apiServer === getAppApiServer()) : user.userID === userData.userObject.userID) && !state.canEdit) ? (
                                    <IconButton className={classes.currentUser} tabIndex={-1} tooltip={t('switch_user_current_account')} tooltipPosition='left' tooltipMaxWidth={400} disableRipple={true}>
                                      <SVG src="checkmark"/>
                                    </IconButton>
                                  ) : null
                                }
                                {
                                  (!user.accessToken && !state.canEdit) ? (
                                    <IconButton className={classes.expiredUser} tabIndex={-1} tooltip={t('switch_user_expired_account')} tooltipPosition='left' tooltipMaxWidth={400} disableRipple={true}>
                                      <SVG src="exclamation-mark"/>
                                    </IconButton>
                                  ) : null
                                }
                                {
                                  (state.canEdit) ? (
                                    <IconButton className={classes.deleteButton} onClick={() => handleDeleteUser(user)} tabIndex={(key + 2 + (1 * key))} dataCy="switchUserDeleteAccountButton" tooltip={t('switch_user_remove_account')} tooltipPosition='left' tooltipMaxWidth={400}>
                                      <SVG src="close"/>
                                    </IconButton>
                                  ) : null
                                }
                                <div className={classes.loginCardTitle}>
                                  {user.firstname} {user.surname}
                                </div>
                                <div className={classes.loginCardAvatarWrapper}>
                                  <div className={classes.loginCardAvatar}>
                                    <AuthenticatedImage thumbLink={user.photo}/>
                                  </div>
                                </div>
                                <form className={classes.loginCardForm} onSubmit={(e: any) => handleContinueAsUser(e, user)}>
                                  <Label>{t('email')}:</Label>
                                  <span>{user.email}</span>
                                  <Label>{t('role')}:</Label>
                                  <span>{t(`role_${getUserRole(user.roleType)}`)}</span>
                                  <div className={classes.footer}>
                                    <div className={classes.button}>
                                      {
                                        user.accessToken ? (
                                          <NormalButton tabIndex={(key + 1 + (1 * key))} type="submit" buttonType="primary" dataCy="switchUserLoginAsUserButton" disabled={state.isLoading || state.canEdit}>
                                            {(useApiServers ? (user.userID === userData.userObject.userID && user.apiServer === getAppApiServer()) : user.userID === userData.userObject.userID) ? t('switch_user_login_continue_current') : t("switch_user_login_continue")}
                                          </NormalButton>
                                        ) : (
                                          <NormalButton tabIndex={(key + 1 + (1 * key))} type="submit" buttonType="primary" dataCy="switchUserLoginAsUserButton" disabled={state.isLoading || state.canEdit}>
                                            {t("switch_user_login_password")}
                                          </NormalButton>
                                        )
                                      }
                                    </div>
                                  </div>
                                </form>
                              </div>
                            ))
                          }
                      </>
                      ) : null
                    }
                  </>
                )
              }
            </>
          ) : (
            <>
            {
              state.users.map((_: any, key: any) => (
                <div className={classes.loginCard} key={`k_${key}`}>
                  <div className={classes.loginCardTitle}>
                    <Skeleton variant="text" animation="wave" width={100} height={25}/>
                  </div>
                  <div className={classes.loginCardAvatarWrapper}>
                    <div className={classes.loginCardAvatar}>
                      <Skeleton variant="circular" animation="wave" width={80} height={80}/>
                    </div>
                  </div>
                  <div className={classes.loginCardForm}>
                    <span className={classes.skeletonLabel}>
                      <Skeleton variant="text" animation="wave" width={100} height={20}/>
                    </span>
                    <Skeleton variant="text" animation="wave" width={100} height={25}/>
                    <span className={classes.skeletonLabel}>
                      <Skeleton variant="text" animation="wave" width={100} height={20}/>
                    </span>
                    <Skeleton variant="text" animation="wave" width={100} height={25}/>
                    <div className={classes.footer}>
                      <div className={classes.button}>
                        <Skeleton variant="rectangular" animation="wave" width={300} height={42}/>
                      </div>
                    </div>
                  </div>
                </div>
                ))
              }
            </>
          )
        }
        {
          state.canAdd ? (
            <div className={classes.loginCard}>
              <div className={classes.loginCardTitle}>
                {t('switch_user_add_account')}
              </div>
              <div className={classes.loginCardAvatarWrapper}>
                <Tooltip title={t('login_teacher_director')} arrow>
                  <div className={classes.loginCardAvatar}>
                    <SVG src="/resources/images/login-teacher"/>
                  </div>
                </Tooltip>
                <Tooltip title={t('login_parent')} arrow>
                  <div className={classes.loginCardAvatar}>
                    <SVG src="/resources/images/login-family"/>
                  </div>
                </Tooltip>
              </div>
              <form className={classes.loginCardForm} onSubmit={handleAddUser}>
                {
                  useApiServers ? (
                    <Select className={classes.select} items={apiServersList} selected={state.currentApiServer} setSelected={handleChangeApiServer} label={t('api_server') + '*'} disabled={state.isLoading} allowClear={false} dataCy='apiSelect'/>
                  ) : null
                }
                <Input placeholder={t('email')} type="text" label={t('email') + '*'} name="email" onChange={onEmailChange} useName={false} disabled={state.isLoading} tabIndex="1" dataCy="emailInput" useForm={false} autoFocus={true}/>
                <Input placeholder={t('password')} type="password" label={t('password') + '*'} name="password" onChange={onPasswordChange} useName={false} disabled={state.isLoading} tabIndex="2" dataCy="passwordInput" useForm={false}/>
                <CheckboxInput className={classes.checkbox} label={t('switch_immediately_login')} checked={state.immediatelyLogin} onChange={handleImmediatelyLogin} disabled={state.isLoading}/>
                <div className={classes.footer}>
                  <div className={classes.button}>
                    <NormalButton tabIndex="4" type="submit" buttonType="primary" dataCy="switchUserAddButton" disabled={state.isLoading}>
                      {state.immediatelyLogin ? t('switch_user_login_add_continue') : t("switch_user_login_add")}
                    </NormalButton>
                  </div>
                  <div className={classes.bottom}>
                    <span onClick={handleAdd} data-cy={isCypress() ? "switchUserBackLink" : null}>{t('cancel')}</span>
                  </div>
                </div>
              </form>
            </div>
          ) : null
        }
      </div>
      {
        state.isFailed ? (
            <div className={classes.loginCard}>
              <div className={classes.loginCardTitle}>
                <span>{t('switch_user_no_accounts')}</span>
              </div>
              <div className={classes.footer}>
                  <div className={classes.button}>
                    <NormalButton tabIndex="1" type="button" onClick={handleEdit} buttonType="primary" dataCy="switchUserAddButton">
                      {t("switch_user_add")}
                    </NormalButton>
                  </div>
                </div>
            </div>
        ) : null
      }
      {
        (state.environments.length > 0 && (config.APP_ENVIRONMENT === "stage" || config.APP_ENVIRONMENT === "review") && config.GITLAB_KEY) ? (
          <div className={classes.selectWrapper}>
            <Select className={classes.select} items={state.environments} selected={getCurrentGitLabEnvironment} setSelected={handleChangeGitLabEnvironment} label={t('gitlab_environment') + '*'} defaultChildTitle='name' disabled={state.isLoading} allowClear={false} dataCy='gitlabEnvironmentSelect'/>
          </div>
        ) : null
      }
      {
        (!state.canAdd && !state.isFailed) ? (
          <div className={classes.bottomRow}>
            <IconButton className={`${state.canEdit ? 'active' : null}`} onClick={handleEdit} tooltip={t('switch_user_manage')} tooltipPosition='top' tooltipMaxWidth={400} dataCy='switchUserManageAccountsButton'>
              <SVG src="pencil"/>
            </IconButton>
            <IconButton onClick={handleAdd} disabled={state.canEdit} tooltip={t('switch_user_add')} tooltipPosition='top' tooltipMaxWidth={400} dataCy='switchUserAddAccountButton'>
              <SVG src="plus"/>
            </IconButton>
          </div>
        ) : null
      }
    </div>
  );
}

export default UserSwitch;