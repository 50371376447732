import React from 'react';
import { getTheme } from './theme';
import { ThemeProvider as JSSThemeProvider } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';

interface Props {
  children: any;
};

const ThemeProvider: React.FunctionComponent<Props> = (props:Props) => {
  
  const layoutData = useAppSelector((state: any) => state.layout);
  const themeScheme = layoutData.theme;
  const theme = getTheme(themeScheme);

  return (
    <JSSThemeProvider theme={theme}>
      {props.children} 
    </JSSThemeProvider>
  );
};

export default ThemeProvider;