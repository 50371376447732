import config from 'src/constants/config';
import htmlParse from 'html-react-parser';
import IconButton from 'src/components/Buttons/IconButton';
import Input from 'src/components/Forms/Input';
import moment from 'src/utils/moment';
import React, { useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import ThreadsList from '../ThreadsList';
import useBreakpoint from 'src/utils/useBreakpoint';
import { Button } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getQueryParams, getQueryString, getSideContentSize } from 'src/utils/useFunctions';
import { getUserHomepage, getUserSetting, saveUserSettings } from 'src/utils/useUser';
import { isNotificationSupported, onMessageListener } from 'src/utils/useFirebase';
import { resetCommunicationList, setCommunicationList, setCommunicationViewMode, updateCommunicationList, updateThreadData } from 'src/store/actions/communication.actions';
import { setCommunicationDrawer } from 'src/store/actions/drawers.actions';
import { setSideContent } from 'src/store/actions/layout.actions';
import { setWarningModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  threads: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 24px)',
    padding: '12px',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      fontSize: '24px',
      fontWeight: '600',
      '& > small': {
        fontSize: '14px',
        fontWeight: '400',
        color: theme.colors.grey[800],
      },
    },
  },
  inputWrapper: {
    padding: '0 12px',
    width: 'calc(100% - 24px)',
  },
  rightColumn: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    flex: '0 0 calc(80% - 0.5px)',
    maxWidth: 'calc(80% - 0.5px)',
  },
  buttons: {
    display: 'flex',
    gap: '4px',
    '& > button': {
      width: '36px',
      height: '36px',
      backgroundColor: theme.colors.grey[200],
      '&:disabled': {
        backgroundColor: theme.colors.grey[150],
      },
      '&.active': {
        backgroundColor: theme.colors.primaryBlue[500],
        color: theme.colors.white,
      },
    },
  },
  backToApp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 52px',
    height: '52px',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.colors.grey[325],
    '& > button': {
      display: 'flex',
      gap: '6px',
      width: '95%',
      height: '85%',
      textTransform: 'unset',
      color: theme.colors.black,
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
}));

type ThreadsListType = {
  isLoading: boolean;
  isLoadingMessages: any;
  currentThreadID: any;
  isFirstTimeLoaded: any;
  setIsLoading: any;
  setIsFirstTimeLoaded: any;
};

const Threads: React.FunctionComponent<ThreadsListType> = ({ isLoading, isLoadingMessages, currentThreadID, isFirstTimeLoaded, setIsLoading, setIsFirstTimeLoaded }) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const communicationData = useAppSelector((state: any) => state.communication);
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);
  const communicationService = useAppSelector((state: any) => state.services).communicationService;
  const threadsList = communicationData.list;

  const isUnreadThreads = communicationData.notificationsCount > 0;
  const unreadThreads = communicationData.notificationsCount;

  const queryParams = getQueryParams(location);
  const queryString = getQueryString(location);
  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;

  const breakpoint: any = useBreakpoint();
  const customViewMode = communicationData.viewMode;
  const currentUrl = customViewMode ? customViewMode : location.pathname.replace("/communication/", "");

  const isPinned = layoutData.sideContent === "communication";
  const isSideContentVisible = getSideContentSize(breakpoint) !== 0;

  const cantCreateNewThread = userData.userObject.schoolSetting.every((schoolSetting: any) => schoolSetting.modules && schoolSetting.modules.communication && schoolSetting.modules.communication.banned === true);

  const limit = 16;

  const [state, setState] = useStates({
    isEndOfScroll: false,
    page: 1,
    order: 'desc',
    lastDateTime: moment(),
    search: "",
    isSaving: false,
  });

  const isSaving = useRef(false);
  const isEndOfScroll = useRef(state.isEndOfScroll);
  const lastDateTimeRef = useRef(state.lastDateTime);

  const handleNavigate = (loc: any) => {
    if(customViewMode) {
      dispatch(setCommunicationViewMode(loc));
    } else {
      navigate(`/communication/${loc}${queryString}`);
    }
  };

  const handleGetNewThreads = useCallback((auto?: any) => {
    setIsLoading((auto && typeof auto === "boolean") ? false : true);
    const settings = {
      dateTimeFrom: lastDateTimeRef.current.format("YYYY-MM-DD HH:mm:ss"),
    };
    communicationService && communicationService.listThreads(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data[config.JSONLD_DATA];
          dispatch(updateCommunicationList(response)); 
          const now = moment();
          setState("lastDateTime", now);
          lastDateTimeRef.current = now;
          if(!isFirstTimeLoaded) {
            setIsFirstTimeLoaded(true);
          }
          setIsLoading(false);
        } else {
          createNotification(t("community_threads_failed_load"), "error");
          setIsLoading(false);
        }
      } else {
        createNotification(t("community_threads_failed_load"), "error");
        setIsLoading(false);
      }
    }).catch(() => {
      createNotification(t("community_threads_failed_load"), "error");
      setIsLoading(false);
    });
  }, [communicationService, dispatch, isFirstTimeLoaded, setIsFirstTimeLoaded, setIsLoading, setState, t]);

  const loadThread = useCallback((customThreadID: any) => {
    const settings = {
      threadID: customThreadID,
    };
    communicationService && communicationService.listThreads(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data[config.JSONLD_DATA];
          const currentThreadData = response[0];
          const newThreadData = {
            threadID: customThreadID,
            data: currentThreadData,
          };
          dispatch(updateThreadData(newThreadData));
        } else {
          createNotification(t("community_thread_failed_load"), "error");
        }
      } else {
        createNotification(t("community_thread_failed_load"), "error");
      }
    }).catch(() => {
      createNotification(t("community_thread_failed_load"), "error");
    });
  }, [dispatch, communicationService, t]);

  const loadThreads = useCallback((page: any, order: any, userID?: any) => {
    if(threadsList.length !== 0) return;
    if(isEndOfScroll.current) return;
    const settings = {
      limit: limit,
      page: page,
      "order[lastMessageDate]": order,
      userID: userID ? userID: '',
    };
    setIsLoading(true);
    communicationService && communicationService.listThreads(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data[config.JSONLD_DATA];
          const count = result.data[config.JSONLD_COUNT];
          dispatch(setCommunicationList(response)); 
          const now = moment();
          setState("lastDateTime", now);
          lastDateTimeRef.current = now;
          if(!isFirstTimeLoaded) {
            setIsFirstTimeLoaded(true);
          }
          if(count <= (limit * page)) {
            setState("isEndOfScroll", true);
            isEndOfScroll.current = true;
          } else {
            setState("page", page + 1);
          }
          setIsLoading(false);
        } else {
          createNotification(t("community_threads_failed_load"), "error");
          setIsLoading(false);
        }
      } else {
        createNotification(t("community_threads_failed_load"), "error");
        setIsLoading(false);
      }
    }).catch(() => {
      createNotification(t("community_threads_failed_load"), "error");
      setIsLoading(false);
    });
  }, [dispatch, communicationService, setState, t, isFirstTimeLoaded, setIsLoading, setIsFirstTimeLoaded, threadsList.length]);

  const loadMoreThreads = useCallback((page: any, order: any, userID?: any) => {
    if(isEndOfScroll.current) return;
    const settings = {
      limit: limit,
      page: page,
      "order[lastMessageDate]": order,
      userID: userID ? userID: '',
    };
    setIsLoading(true);
    communicationService && communicationService.listThreads(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          const response = result.data[config.JSONLD_DATA];
          const count = result.data[config.JSONLD_COUNT];
          dispatch(updateCommunicationList(response)); 
          setState("lastDateTime", moment());
          if(!isFirstTimeLoaded) {
            setIsFirstTimeLoaded(true);
          }
          if(count < limit * page) {
            setState("isEndOfScroll", true);
            isEndOfScroll.current = true;
          } else {
            setState("page", page + 1);
          }
          setIsLoading(false);
        } else {
          createNotification(t("community_threads_failed_load"), "error");
          setIsLoading(false);
        }
      } else {
        createNotification(t("community_threads_failed_load"), "error");
        setIsLoading(false);
      }
    }).catch(() => {
      createNotification(t("community_threads_failed_load"), "error");
      setIsLoading(false);
    });
  }, [dispatch, communicationService, setState, t, isFirstTimeLoaded, setIsLoading, setIsFirstTimeLoaded]);

  const handleGetThreads = () => {
    if(threadsList.length === 0) {
      loadThreads(1, state.order);
    } else {
      handleGetNewThreads();
    }
  };

  const handleNewMessage = () => {
    if(cantCreateNewThread) {
      const settings = {
        isOpen: true,
        title: t('function_unavailable'),
        content: t('function_unavailable_communication_new'),
      };
      dispatch(setWarningModal(settings));
      return;
    }
    if(currentUrl === "new") {
      handleNavigate("threads");
    } else {
      handleNavigate("new");
    }
  };

  const handleOrder = () => {
    setIsLoading(true);
    handleNavigate("threads");
    dispatch(resetCommunicationList());
    const page = 1;
    let order = "desc";
    if(state.order === "desc") {
      order = "asc";
    }
    setState("page", page);
    setState("order", order);
    setState("isEndOfScroll", false);
    isEndOfScroll.current = false;
    loadMoreThreads(page, order);
  };

  const handleSettings = () => {
    if(currentUrl === "settings") {
      handleNavigate("threads");
    } else {
      handleNavigate("settings");
    }
  };

  const handleSaveSideContent = async (value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      setState("isSaving", true);
      const newValue = value;
      const result = await saveUserSettings(dispatch, userData, "customizations", ["app", "sidecontent"], newValue);
      if(result) {
        isSaving.current = false;
        setState("isSaving", false);
      } else {
        createNotification(t("user_settings_not_saved"), "error");
        isSaving.current = false;
        setState("isSaving", false);
      }
    }
  };

  const handlePin = () => {
    if(isPinned) {
      dispatch(setSideContent(null));
      handleSaveSideContent(null);
    } else {
      const settings = {
        isOpen: false,
        position: 'right',
      };
      dispatch(setCommunicationDrawer(settings));
      dispatch(setSideContent("communication"));
      handleSaveSideContent("communication");
    }
  };

  const handleGoBack = () => {
    navigate(getUserHomepage(dispatch, dataData, layoutData, userData));
  };

  const handleSearchChange = (name: any, value: any, e: any) => {
    handleNavigate("threads");
    e.stopPropagation();
    setState(name, value.toLowerCase());
    setIsLoading(true);
    setState("page", 1);
    setState("isEndOfScroll", false);
    isEndOfScroll.current = false;
    dispatch(resetCommunicationList());
    if(value.length === 0) {
      loadMoreThreads(1, state.order);
    }
  };

  useEffect(() => {
    loadThreads(state.page, state.order);
  }, [dispatch, loadThreads, state.page, state.order], []);

  useEffect(() => {
    if(state.search) {
      if(state.search.length !== 0) {
        const search = state.search.toLowerCase();
        const users = dataData.users.filter((item: any) => item.displayName && item.displayName.toLowerCase().includes(search)).map((item: any) => { return item.userID; });
        loadThreads(1, state.order, users.length === 0 ? "-1" : users.join(","));
      }
    }
  }, [state.search, state.order, dataData.users, loadThreads], [state.search]);

  const autoRefresh = getUserSetting(userData.userSettings, "customizations", ["communication", "autorefresh_threads"]);

  useEffect(() => {
    if(autoRefresh > 0) {
      let getMessagesInterval;
      getMessagesInterval = setInterval(() => {
        handleGetNewThreads(true);
      }, autoRefresh);
      return () => {
        clearInterval(getMessagesInterval);
      };
    }
  }, [autoRefresh, handleGetNewThreads], []);

  useEffect(() => {
    if(threadsList.length !== 0 && unreadThreads > 0) {
      handleGetNewThreads(true);
    }
  }, [handleGetNewThreads, unreadThreads, threadsList], [unreadThreads]);

  if(userData.userStatus === "loggedIn" && isNotificationSupported) {
    onMessageListener().then((payload: any) => {
      if(payload.data && payload.data.type && payload.data.type === "chat") {
        if(payload.data.threadID) {
          const notificationThreadID = parseInt(payload.data.threadID);
          if(notificationThreadID !== currentThreadID) {
            loadThread(notificationThreadID);
          }
        }
      }
    });
  }
  
  return (
    <div className={classes.threads}>
      <div className={classes.header}>
        <span>{t('communication_threads')}{isUnreadThreads ? htmlParse(`<small>(${unreadThreads})</small>`) : ''}</span>
        <div className={classes.buttons}>
          <IconButton className={currentUrl === "new" ? 'active' : null} onClick={handleNewMessage} tooltip={t('communication_thread_new')} tooltipPosition='bottom' tooltipMaxWidth={400}>
            <SVG src="pencil-new"/>
          </IconButton>
          <IconButton onClick={handleGetThreads} tooltip={t('communication_threads_reload')} tooltipPosition='bottom' tooltipMaxWidth={400} disabled={isLoading}>
            <SVG src="reload"/>
          </IconButton>
          <IconButton onClick={handleOrder} tooltip={t('communication_threads_sort')} tooltipPosition='bottom' tooltipMaxWidth={400} disabled={isLoading}>
            <SVG src={state.order === "desc" ? "sort-down" : "sort-up"}/>
          </IconButton>
          <IconButton className={currentUrl === "settings" ? 'active' : null} onClick={handleSettings} tooltip={t('communication_settings')} tooltipPosition='bottom' tooltipMaxWidth={400}>
            <SVG src="cog"/>
          </IconButton>
          {
            (customViewMode && isSideContentVisible) ? (
              <IconButton onClick={handlePin} tooltip={isPinned ? t('sidecontent_unpin') : t('sidecontent_pin')} tooltipPosition='bottom' tooltipMaxWidth={400}>
                <SVG src={isPinned ? "pin" : "pin-outlined"}/>
              </IconButton>
            ) : null
          }
        </div>
      </div>
      <Input name="search" className={classes.inputWrapper} placeholder={t('communication_search_users')} prepend={<SVG src="search"/>} onInputEnd={handleSearchChange} disabled={isLoading} inputDelay={1500} inputNoDelayOnEmpty={true}/>
      <ThreadsList isLoading={isLoading} isLoadingMessages={isLoadingMessages} isEndOfScroll={state.isEndOfScroll} currentThreadID={currentThreadID} page={state.page} order={state.order} loadThreads={loadMoreThreads}/>
      {
        (!customViewMode && !isEmbed) ? (
          <div className={classes.backToApp}>
            <Button onClick={handleGoBack}>
              <SVG src="arrow-left"/>
              {t('back_to_app')}
            </Button>
          </div>
        ) : null
      }
    </div>
  );
};

export default Threads;